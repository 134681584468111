import React from 'react';
import Box from '@material-ui/core/Box';

const QrsTabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ height: '100%' }}>
          {children}
        </Box>
      )}
    </div>
  );
};

export default QrsTabPanel;
