import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Button, Grid, Typography, Snackbar } from '@material-ui/core';
import MapIcon from '@material-ui/icons/Map';
import { useStateValue } from 'context/store';
import { I18nContext } from 'translations';
import { withRouter } from 'react-router-dom';
import {
  getStopPoint,
  goToCreatePibox,
  createMultipleBookings,
  getListOptimizedRoutes,
  getListRouteNotOptimized,
  downloadExcelOptimizeRoutes,
} from 'context/operations/optimizeRoutes/actions';
import { message as closeMessage } from 'context/commons/actions';
import OptimizedRoutesTable from 'components/Tables/Routes/OptimizedRoutes';
import ListRoutes from 'components/Routes/ListRoutes';
import PolylineMap from 'components/Maps/Routes/PolylineMap';
import { INITIAL_PAGE, PER_PAGE } from 'utils/constants';
import { modalMessage } from 'utils';
import Loading from 'components/Loading';
import TitleWithDowload from 'components/Titles/TitleWithDowload';

function ListOptimizedRoutes({ match, history }) {
  const { translate } = useContext(I18nContext);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRoutes, setSelectedRoutes] = useState([]);
  const [selectedStop, setSelectedStop] = useState();
  const [routeData, setRouteData] = useState([]);
  const [per_page] = useState(PER_PAGE);
  const {
    params: { id },
  } = match;
  const [
    {
      optimizeRoutes: {
        listOptimizedRoutes,
        routeNotOptimized,
        loadingListOptimizedRoutes,
        listStopPoint,
        routeOptimizeOps,
      },
      commons: { message }
    },
    dispatch,
  ] = useStateValue();

  const getListStopPoint = useCallback(async () => {
    getStopPoint(dispatch, id);
  }, [dispatch, id]);

  useEffect(() => {
    getListStopPoint();
  }, [getListStopPoint]);

  const listRouteNotOptimized = useCallback(async () => {
    getListRouteNotOptimized(dispatch, id);
  }, [dispatch, id]);

  useEffect(() => {
    listRouteNotOptimized();
  }, [listRouteNotOptimized]);

  const getOptimizedRoutes = useCallback(
    async (page) => {
      getListOptimizedRoutes(dispatch, { page, per_page }, id);
    },
    [dispatch, per_page, id]
  );

  useEffect(() => {
    getOptimizedRoutes(INITIAL_PAGE);
  }, [getOptimizedRoutes]);

  const handleChangePage = (type) => {
    if (type === 'inc') {
      getOptimizedRoutes(currentPage + 1);
      setCurrentPage(currentPage + 1);
    }
  };

  const handleChangeSelectedStop = (e, task, job) => {
    e.stopPropagation();
    setSelectedStop({
      id: task.stop_index,
      route: job.route_index,
      lat: task.lat,
      lon: task.lon,
      address: task.address,
    });
  };

  useEffect(() => {
    routeOptimizeOps && history.push('/app/booking/new');
  }, [routeOptimizeOps, history, dispatch]);

  const goToCreateBooking = () => goToCreatePibox(dispatch, routeData);

  const messageOk = () => {
    setLoading(false);
    modalMessage(
      translate('congratulations'),
      translate('app.operations.optimize.servicesCreatedSuccessfully'),
      'success',
      translate('accept'),
      true
    ).then(() => {
      history.push('/app/bookings');
    });
  };

  const goToCreateMultipleBookings = async () => {
    modalMessage(
      '',
      translate('app.operations.optimize.confirmMessageCreateBookings'),
      'warning',
      'Continue',
      true,
      true,
      translate('cancel')
    ).then(async (response) => {
      if (response.isConfirmed) {
        setLoading(true);
        const callback = await createMultipleBookings(dispatch, routeData);
        callback && callback.status ? messageOk() : setLoading(false);
      }
    });
  };

  const downloadExcel = async () => {
    await downloadExcelOptimizeRoutes({ dispatch, id});
  };

  return (
    <div className="padding-page-full-height">
      <TitleWithDowload
        title={translate('app.operations.optimize.title')}
        icon={<MapIcon className="align-center" style={{ marginRight: 5 }} />}
        button={translate('app.bookings.download')}
        downloadExcel={downloadExcel}
      />

      <div className="marginFiltersTable">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item lg={7} xs={12}>
            {loading && <Loading />}
            {loadingListOptimizedRoutes && <Loading />}
            {listOptimizedRoutes && (
              <>
                <ListRoutes
                  data={listOptimizedRoutes}
                  handleChangePage={handleChangePage}
                  handleChangeSelectedRoutes={setSelectedRoutes}
                  page={currentPage}
                  perPage={10}
                  handleChangeSelectedStop={handleChangeSelectedStop}
                  selectedStop={selectedStop}
                  setRouteData={setRouteData}
                />
                <div className="text-center">
                  {routeData && routeData.length > 1 ? (
                    <Button
                      onClick={goToCreateMultipleBookings}
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      {translate('createServices')}
                    </Button>
                  ) : (
                    <Button
                      onClick={goToCreateBooking}
                      variant="contained"
                      color="primary"
                      disabled={
                        routeData && routeData.length === 1 ? false : true
                      }
                    >
                      {translate('app.createService')}
                    </Button>
                  )}
                </div>
              </>
            )}
            {routeNotOptimized && routeNotOptimized.length > 0 && (
              <>
                <Typography variant="h5" color="secondary">
                  {translate('app.operations.optimize.unoptimizedRoutes')}
                </Typography>
                <br />
                <OptimizedRoutesTable tasks={routeNotOptimized || []} />
              </>
            )}
          </Grid>
          <Grid item lg={5} xs={12}>
            {listStopPoint && listOptimizedRoutes && (
              <PolylineMap
                points={listStopPoint.data || []}
                selectedRoutes={selectedRoutes}
                routes={listOptimizedRoutes.data}
                selectedStop={selectedStop}
                handleChangeSelectedStop={handleChangeSelectedStop}
              />
            )}
          </Grid>
        </Grid>
      </div>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </div>
  );
}

export default withRouter(ListOptimizedRoutes);
