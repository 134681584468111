import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Grid, TextField, Typography, Button, FormControlLabel, Checkbox, Select, FormControl, InputLabel, FormLabel, RadioGroup, Radio } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { getCostCenter } from 'context/express/actions';
import { I18nContext } from 'translations';
import AutocompleteInput from 'components/CustomInputs/AutocompleteInput';
import ModalMap from 'components/Modals/ModalMap';
import { PIBOX_CAR_SERVICE_TYPE_ID, SERVICE_TYPE_ID } from 'utils/constants';

const OriginFields = ({
  origin,
  handleSuggest,
  register,
  city,
  type,
  changeCoord,
  loadingAproxAddress,
  Controller,
  control,
  company,
  vehicleTypes,
  listVehicles,
  counterDelivery,
  driverWithMoneyBalance,
  isRent,
  availableServiceType,
  favoritesAsOrigins,
  moneyBalance
}) => {
  const { translate } = useContext(I18nContext);
  const [open, setOpen] = useState(false);
  const [costCenter, setCostCenter] = useState([]);
  const handleModalMap = () => setOpen(!open);

  const listCostCenter = useCallback(async () => {
    const callback = company && await getCostCenter(company._id);
    callback && callback.costCenter && setCostCenter(callback.costCenter);
  }, [company])

  useEffect(() => {
    listCostCenter();
  }, [listCostCenter])

  const loadVehicles = listVehicles && vehicleTypes === 'cargo' ? listVehicles : null;

  if (city.lat !== 0) {
    return (
      <Grid item container justify="center" alignItems="flex-start" spacing={2}>
        {company && !isRent &&
          <Grid item md={12} sm={12} xs={12}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel>
                {translate('app.express.typeOfService')}
              </InputLabel>
              <Select
                native
                inputRef={register}
                label={translate('app.express.typeOfService')}
                name="vehicle_types"
                variant="outlined"
                fullWidth
                size="small"
                defaultValue={availableServiceType?.filter(x => x === 'pibox')?.length > 0 ? 'moto' : ''}
              >
                {availableServiceType?.map((type) => {
                  return <>
                    {type === 'pibox' && <option key={type} value='moto'>{translate('typeOfService.express')}</option>}
                    {type === 'freight' && !isRent && <option key={type} value='cargo'>{translate('app.express.vehicleType.cargo')}</option>}
                  </>
                })}
                <option key='car' value={PIBOX_CAR_SERVICE_TYPE_ID}>{translate('app.express.vehicleType.car')}</option>
              </Select>
            </FormControl>
            {loadVehicles && loadVehicles.length > 0 &&
              <FormControl variant="outlined" fullWidth size="small" style={{ marginTop: 18 }}>
                <InputLabel>
                  {translate('app.express.selectVehicle')}
                </InputLabel>
                <Select
                  native
                  inputRef={register}
                  label={translate('app.express.selectVehicle')}
                  name="vehicle"
                  variant="outlined"
                  fullWidth
                  size="small"
                  defaultValue=""
                >
                  {loadVehicles.map((vehicle =>
                    <option key={vehicle._id} value={vehicle._id}>
                      {vehicle.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            }
          </Grid>
        }
        <Grid item md={12} sm={6} xs={12}>
          <AutocompleteInput
            title={translate('app.express.address')}
            name="address"
            address={origin && origin}
            cityId={city.id}
            pos={{ lat: city.lat, lon: city.lon }}
            focus
            handleChange={(info) => handleSuggest(info, 0, 0)}
            disabled={favoritesAsOrigins}
          />
          {!favoritesAsOrigins && <Typography
            color="textSecondary"
            variant="caption"
            className="line-height-xs"
          >
            {translate('app.express.selectAddressFromTheListOfSuggestions')}
            <Button type="button" size="small" onClick={handleModalMap} color="primary" style={{ fontSize: '0.75rem', padding: '0px 5px' }}>
              {translate('app.express.help')}
            </Button>
          </Typography>}
        </Grid>
        <Grid item md={12} sm={6} xs={12}>
          <TextField
            type="text"
            name="secondary_address"
            variant="outlined"
            label={translate('app.express.supplementaryAddress')}
            fullWidth
            inputRef={register}
            size="small"
          />
        </Grid>
        {!driverWithMoneyBalance && !isRent && (
          <>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <FormControlLabel
              control={
                <Controller
                  name="requires_a_driver_with_base_money"
                  defaultValue={counterDelivery || false}
                  control={control}
                  render={(props) => (
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon fontSize="small" />}
                      checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label={translate('app.express.driverHaveToPayProductCollected')}
            />
          </Grid>
          {moneyBalance &&
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <TextField
                name="money_to_pay_for_package"
                label={translate('app.express.amountToBePaid')}
                variant="outlined"
                size="small"
                type="text"
                defaultValue={0}
                fullWidth
                inputRef={register({
                  pattern: {
                    value: /^[0-9]+$/,
                    message: translate('onlyNumbers'),
                  },
                })}
              />
            </Grid>
          }
          </>
        )}
        {costCenter && costCenter.length > 0 && (
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel>
                {translate('app.bookings.costCenter')}
              </InputLabel>
              <Select
                native
                inputRef={register}
                label={translate('app.bookings.costCenter')}
                name="cost_center_id"
                variant="outlined"
                fullWidth
                size="small"
                defaultValue={costCenter[0]._id}
              >
                {costCenter.map(center => (
                  <option key={center._id} value={center._id}>
                    {center.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {isRent && (
          <Grid item xs={12}>
            <FormControl style={{ marginBottom: 10 }}>
              <FormLabel component="legend" style={{ margin: '10px 0' }}>
                <b>{translate('app.express.vehicleType')}</b><br />
              </FormLabel>
              <Controller
                control={control}
                name="vehicle_types"
                defaultValue={SERVICE_TYPE_ID}
                as={
                  <RadioGroup row>
                    <FormControlLabel value={SERVICE_TYPE_ID} control={<Radio size="small" />} label={translate('app.express.vehicleType.motorcycle')} />
                    <FormControlLabel value={PIBOX_CAR_SERVICE_TYPE_ID} control={<Radio size="small" />} label={translate('app.express.vehicleType.car')} />
                  </RadioGroup>
                }
              />
            </FormControl>
          </Grid>
        )}
        {open && (
          <ModalMap
            open={open}
            handleModalMap={handleModalMap}
            city={city}
            changeAddress={changeCoord}
            address={origin}
            type={type}
            loadingAproxAddress={loadingAproxAddress}
          />
        )}
      </Grid>
    )
  } else {
    return (
      <Typography
        color="primary"
        style={{ margin: 'auto' }}
      >
        {translate('app.express.chooseACityToCreateAPiboxExpress')}
      </Typography>
    )
  }
}

function areEqual(prevProps, nextProps) {
  return prevProps.origin === nextProps.origin
    && prevProps.register === nextProps.register
    && prevProps.loadingAproxAddress === nextProps.loadingAproxAddress
    && prevProps.driverWithMoneyBalance === nextProps.driverWithMoneyBalance
    && prevProps.moneyBalance === nextProps.moneyBalance
    && prevProps.vehicleTypes === nextProps.vehicleTypes
}

export default React.memo(OriginFields, areEqual);