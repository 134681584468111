import axios from 'axios';

/** Petición GET estándar con axios */
export const get = async (urlRequest, params) => {
  const default_token = localStorage.getItem('session_token');
  const lang = localStorage.getItem('lang') || 'es';
  const sendParams = Object.assign({}, params);
  const response = await axios({
    headers: {
      'X-PI-TOKEN': default_token || '',
      lang,
    },
    url: urlRequest,
    method: 'GET',
    params: sendParams,
  });
  return response;
};

/** Petición POST estándar con axios */
export const post = async (urlRequest, dataRequest) => {
  const default_token = localStorage.getItem('session_token');
  const lang = localStorage.getItem('lang') || 'es';
  const response = await axios({
    headers: {
      'X-PI-TOKEN': default_token || '',
      lang,
    },
    url: urlRequest,
    method: 'POST',
    data: dataRequest,
  });
  return response;
};

/** Petición PATCH estándar con axios */
export const patch = async (urlRequest, dataRequest, token = '') => {
  const default_token = localStorage.getItem('session_token');
  const lang = localStorage.getItem('lang') || 'es';

  const isFormData = dataRequest instanceof FormData;

  const response = await axios({
    headers: {
      'X-PI-TOKEN': token || default_token,
      lang,
      ...(isFormData ? { 'Content-Type': 'multipart/form-data' } : {}),
    },
    url: urlRequest,
    method: 'PATCH',
    data: dataRequest,
  });
  return response;
};

/** Petición PUT estándar con axios */
export const put = async (urlRequest, dataRequest) => {
  const default_token = localStorage.getItem('session_token');
  const lang = localStorage.getItem('lang') || 'es';
  const response = await axios({
    headers: {
      'X-PI-TOKEN': default_token || '',
      lang,
    },
    url: urlRequest,
    method: 'PUT',
    data: dataRequest,
  });
  return response;
};

/** Petición DELETE estándar con axios */
export const deleteD = async (urlRequest, dataRequest) => {
  const default_token = localStorage.getItem('session_token');
  const lang = localStorage.getItem('lang') || 'es';
  const response = await axios({
    headers: {
      'X-PI-TOKEN': default_token || '',
      lang,
    },
    url: urlRequest,
    method: 'DELETE',
    data: dataRequest,
  });
  return response;
};

/** Petición DELETE con parámetros estándar con axios */
export const deleteP = async (urlRequest, params) => {
  const default_token = localStorage.getItem('session_token');
  const lang = localStorage.getItem('lang') || 'es';
  const sendParams = Object.assign({}, params);
  const response = await axios({
    headers: {
      'X-PI-TOKEN': default_token || '',
      lang,
    },
    url: urlRequest,
    method: 'DELETE',
    params: sendParams,
  });
  return response;
};

/** Obtiene y fuerza la descarga de un archivo Excel desde el servidor */
export const getExcelFile = async (urlRequest, params) => {
  const default_token = localStorage.getItem('session_token');
  const lang = localStorage.getItem('lang') || 'es';
  const response = await axios({
    url: `${urlRequest}.xlsx`,
    method: 'GET',
    params: {
      ...params
    },
    responseType: 'blob',
    headers: {
      'X-PI-TOKEN': default_token,
      lang
    }
  }).then((httpResponse) => {
    const url = window.URL.createObjectURL(
      new Blob([httpResponse.data])
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Reporte.xlsx');
    document.body.appendChild(link);
    link.click();
  });
  return response;
};

/** Petición GET con token específico */
export const getTest = async (urlRequest, params) => {
  const lang = localStorage.getItem('lang') || 'es';
  const sendParams = Object.assign({}, params);
  const response = await axios({
    headers: {
      'x-pibox-e-token': 'ShtSPVS9RgyDpvgTjq0m6_SDm8C9D3kjpp0ef8p2uT0XX0-23wQVWw',
      lang,
    },
    url: urlRequest,
    method: 'GET',
    params: sendParams,
  });
  return response;
};

/** Petición para subir videos a la API `/api/web/chat_temporary_files` */
export const uploadVideoToApi = async (urlRequest, file) => {
  try {
    const formData = new FormData();
    
    formData.append("chat_temporary_file[video]", file);

    const response = await axios.post(
      urlRequest,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-PI-TOKEN": localStorage.getItem("session_token"),
        },
      }
    );

    return {Location: response?.data?.url};
  } catch (error) {
    throw error;
  }
};