import React from 'react';
import { Snackbar, Button, Typography, Paper, Grid } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CloseIcon from '@material-ui/icons/Close';

export default function MessageInfo({
  handleClose,
  buttonText,
  horizontal,
  vertical,
  openSync,
  goToPage,
  title,
  text
}) {

  return(
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={openSync}
      onClose={handleClose}
      key={vertical + horizontal}
    >
      <Paper style={{ minWidth: '400px', maxWidth: '680px', padding: '10px 2px'}}>
        <Grid container>
          <Grid item md={1} className='text-center'>
            <AccessTimeIcon style={{ color: '#ffa726', marginTop: '4px' }} />
          </Grid>
          <Grid item md={10}>
            <Typography variant="subtitle1"> 
              <b>{title}</b>
            </Typography>
            <Typography variant="body2" style={{ marginBottom: '5px' }}>
              {text}
            </Typography>
            <Button type="button" onClick={goToPage} color="primary">
              {buttonText}
            </Button>
          </Grid>
          <Grid item md={1} className='text-center'>
            <Button
              onClick={handleClose}
              size='small'
              style={{ minWidth: 'auto', padding: '2px' }}
            >
              <CloseIcon size='small' style={{ color: '#828080' }} />
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Snackbar>
  )
}