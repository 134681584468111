const initialState = {
  session: {
    session: null,
  },
  commons: {
    loading: false,
    message: null,
  },
  tracking: {
    tracking: null,
    events: [],
    rating: null,
  },
  checkin: {
    checkin: null,
    message: null,
    company: null,
    configutation: null,
    loading: false,
  },
  bookings: {
    booking: null,
    quote: null,
    loadingQuote: false,
    bulkLoad: null,
    bulkLoadFailedStops: null,
    loadingBulkLoad: false,
    optimize: null,
    list: null,
    loadingBookings: false,
    listPackages: null,
    loadingPackages: false,
    bookingSearchByGroup: null,
    listByGroups: null,
    relatedCities: null,
    totalWeight: null,
    reports: null,
    failedScheduled: null,
    balance: null
  },
  sales: {
    list: null,
    loading: false,
    saleList: null,
  },
  integrations: {
    list: null,
    loading: false,
    message: null,
    listShopifyStores: null,
  },
  payments: {
    listPicash: null,
    loadingPicash: false,
    bankAccounts: null,
    bankAccountsLoading: false,
    bankList: null,
    accountTypeList: null,
    documentTypeList: null,
    bankAccountAvailable: null,
    rechargeMethods: null,
    pseParams: null,
  },
  users: {
    listUsers: null,
    loadingUsers: false,
    userExists: null,
    validatingUser: false,
    loadingCreateUserCompany: false,
  },
  groups: {
    listGroups: null,
    loadingGroups: false,
    loadingGroupUserList: false,
    groupUserList: null,
  },
  profile: {
    loadingPassword: false,
    loadingProfile: false,
  },
  addresses: {
    listAddresses: null,
    loadingAddresses: false,
  },
  servicesOperations: {
    list: null,
  },
  optimizeRoutes: {
    failedRoutes: [],
    listOptimizedRoutes: null,
    listRouteFile: null,
    loadingListRouteFile: false,
    routeNotOptimized: null,
    loadingListOptimizedRoutes: false,
    listStopPoint: null,
  },
  unqulifiedBookings: {
    list: null,
  },
  booking: {
    list: null,
    amountLeftToCollectByCompany: null,
    events: null,
  },
  companyRegister: {
    data: null,
    loading: false,
  },
  prepackages: {
    list: null,
    loadingPrepackages: false,
  },
  whatsapp: {
    session: null,
    isLoading: null,
    isFetching: null,
  },
  promoCodes: {
    list: null,
    loadingPromoCode: false,
  },
  commodateLoan: {
    list: null,
    commodate: null,
  },
  claims: {
    list: null
  },
  balances: {
    list: null,
    loadingBalances: false,
    listTransactions: null,
    listTransactionsLoading: false
  },
  security: {
    security: null,
    loadingSecurity: false
  },
  qrs: {
    list: null,
    loadingQr: true,
  },
};

export default initialState;
