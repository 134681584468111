import { Grid, Typography, colors } from '@material-ui/core';
import React, { useContext } from 'react';
import Subtitle from './Subtitle';
import DesignPreview from './DesignPreview';
import { I18nContext } from 'translations';

const QrDigitalConfirm = () => {
  const { translate } = useContext(I18nContext);
  return (
    <Grid container spacing={2}>
      <Subtitle text={ translate('app.payments.qrCode.create.confirmationDigital.title') } />
      <Grid item xs={12}>
        <Grid container justify="center">
          <Typography variant="subtitle1">{ translate('app.payments.qrCode.create.confirmationDigital.confirmMessage',) }</Typography>
          <Typography variant="body2">
            { translate('app.payments.qrCode.create.confirmationDigital.description') }
          </Typography>
          <Grid item sx={12} sm={8} md={4}>
            <Grid container justify="center" />
            <Grid
              item
              style={{
                padding: '1rem',
                borderRadius: '1rem',
                border: `2px dotted ${colors.grey[300]}`,
              }}
            >
              <DesignPreview isDigital />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QrDigitalConfirm;
