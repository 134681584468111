import React, { useContext, useState } from 'react';
import { Grid, TextField, Checkbox, FormControl, FormControlLabel, Typography, InputLabel, Select, Button } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import HelpIcon from '@material-ui/icons/Help';
import ModalPackages from 'components/Modals/ModalPackages';
import { I18nContext } from 'translations';
import { SIZE_CD } from 'utils/constants';

const PackageFields = ({
  index,
  register,
  errors,
  counterDelivery,
  item,
  company
}) => {
  const { translate } = useContext(I18nContext);
  const [open, setOpen] = useState(false);
  const [checkedDelivery, setCheckedDelivery] = useState(item && item.packages ? item.packages[0].counter_delivery : false);

  const handleOpen = () => setOpen(true);
  
  const handleClose = () => setOpen(false);

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {translate('app.express.packageInformation')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <input
          type="hidden"
          name={`stops[${index}].packages[0].delivery_sale_id`}
          ref={register()}
          defaultValue={item && item.packages && item.packages[0].delivery_sale_id} 
        />
        <TextField
          name={`stops[${index}].packages[0].indications`}
          defaultValue={item && item.packages && translate(item.packages[0].indications)}
          label={translate('app.express.whatShouldTheDeliveryCourierDo')}
          variant="outlined"
          id={`indications${index}`}
          multiline
          rows={3}
          size="small"
          fullWidth
          autoFocus
          required
          inputRef={register({
            required: translate('requiredField')
          })}
          error={
            errors.stops &&
            errors.stops[index] &&
            errors.stops[index].packages &&
            errors.stops[index].packages[0] &&
            !!errors.stops[index].packages[0].indications
          }
          helperText={
            errors.stops &&
            errors.stops[index] &&
            errors.stops[index].packages &&
            errors.stops[index].packages[0] &&
            errors.stops[index].packages[0].indications &&
            errors.stops[index].packages[0].indications.message
          }
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <FormControl variant="outlined" fullWidth size="small">
          <InputLabel htmlFor={`size_cd${index}`} id={`size_cd${index}`}>
            {translate('app.express.size')} *
          </InputLabel>
          <Select
            native
            inputRef={register()}
            label={`${translate('app.express.size')} *`}
            name={`stops[${index}].packages[0].size_cd`}
            defaultValue={item && item.packages ? item.packages[0].size_cd : 0}
          >
            {Object.values(SIZE_CD).map((size, index) => (
              <option key={index.toString()} value={index}>
                {translate(size)}
              </option>
            ))}
          </Select>
          <div className="text-right">
            <Button
              onClick={handleOpen}
              size="small"
              color="primary"
              style={{ minWidth: 'auto', fontSize: '0.87rem', paddingBottom: 0 }}
              endIcon={<HelpIcon style={{ fontSize: "1.17em" }} />}
            >
              {translate('needHelp')}
            </Button>
          </div>
        </FormControl>
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <TextField
          name={`stops[${index}].packages[0].declared_value`}
          defaultValue={item && item.packages && item.packages[0].declared_value}
          label={translate('app.express.declaredValue')}
          variant="outlined"
          inputProps={{ maxLength: '10' }}
          size="small"
          type="text"
          fullWidth
          required
          inputRef={register({
            required: translate('requiredField'),
            pattern: {
              value: /^[0-9]+$/,
              message: translate('onlyNumbers'),
            },
          })}
          error={
            errors.stops &&
            errors.stops[index] &&
            errors.stops[index].packages &&
            errors.stops[index].packages[0] &&
            !!errors.stops[index].packages[0].declared_value
          }
          helperText={
            errors.stops &&
            errors.stops[index] &&
            errors.stops[index].packages &&
            errors.stops[index].packages[0] &&
            errors.stops[index].packages[0].declared_value &&
            errors.stops[index].packages[0].declared_value.message
          }
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          name={`stops[${index}].packages[0].reference`}
          defaultValue={item && item.packages && item.packages[0].reference}
          label={translate('app.express.orderNumber')}
          variant="outlined"
          size="small"
          fullWidth
          required
          inputRef={register({
            required: translate('requiredField')
          })}
          error={
            errors.stops &&
            errors.stops[index] &&
            errors.stops[index].packages &&
            errors.stops[index].packages[0] &&
            !!errors.stops[index].packages[0].reference
          }
          helperText={
            errors.stops &&
            errors.stops[index] &&
            errors.stops[index].packages &&
            errors.stops[index].packages[0] &&
            errors.stops[index].packages[0].reference &&
            errors.stops[index].packages[0].reference.message
          }
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          name={`stops[${index}].packages[0].weight`}
          defaultValue={item && item.packages && item.packages[0].weight}
          label={translate('app.express.weightInGrams')}
          variant="outlined"
          size="small"
          fullWidth
          inputRef={register({
            pattern: {
              value: /^[0-9]+$/,
              message: translate('onlyNumbers'),
            },
          })}
          error={
            errors.stops &&
            errors.stops[index] &&
            errors.stops[index].packages &&
            errors.stops[index].packages[0] &&
            !!errors.stops[index].packages[0].weight
          }
        />
      </Grid>
      {company && !counterDelivery && (
        <Grid item xs={12}>
          <FormControl style={{ marginBottom: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<RadioButtonUncheckedIcon fontSize="small" />}
                  checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                  name={`stops[${index}].packages[0].counter_delivery`}
                  inputRef={register()}
                  defaultChecked={item && item.packages ? item.packages[0].counter_delivery : false}
                  onChange={e => setCheckedDelivery(e.target.checked)}
                />
              }
              label={translate('app.express.shouldTheDriverCollectMoney')}
            />
          </FormControl>
          {checkedDelivery && (
            <TextField
              name={`stops[${index}].packages[0].collected_value`}
              defaultValue={item && item.packages && item.packages[0].collected_value ? item.packages[0].collected_value : ''}
              label={translate('app.express.collectedValue')}
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              helperText={translate('app.express.collectedValue.description')}
              inputRef={register({
                pattern: {
                  value: /^[0-9]+$/,
                  message: translate('onlyNumbers'),
                },
                required: checkedDelivery ? translate('requiredField') : false
              })}
            />
          )}
        </Grid>
      )}
      <ModalPackages handleClose={handleClose} open={open} />
    </Grid>
  )
}

function areEqual(prevProps, nextProps) {
  return prevProps.item === nextProps.item
    && prevProps.counterDelivery === nextProps.counterDelivery
}

export default React.memo(PackageFields, areEqual);
