import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#7825bd',
    },
    secondary: {
      main: '#ff0068',
    },
    text: {
      primary: '#545454',
      secondary: '#828080',
      bold: '#111827',
    },
    action: {
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.05,
    },
    blank: {
      main: '#ffffff',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: ['montserratregular'].join(','),
    button: {
      textTransform: 'none',
      fontFamily: 'montserratmedium',
      fontSize: '0.95rem',
    },
    h1: {
      fontFamily: 'amaranthBold',
    },
    h2: {
      fontFamily: 'amaranthBold',
    },
    h3: {
      fontFamily: 'montserratmedium',
    },
    h4: {
      lineHeight: 1.2,
      fontFamily: 'amaranthbold',
    },
    h5: {
      fontFamily: 'amaranthregular',
    },
    h6: {
      fontFamily: 'montserratregular',
    },
    subtitle1: {
      fontSize: 18,
      fontFamily: 'amaranthbold',
    },
    subtitle2: {
      fontSize: '1rem',
      color: '#828080',
      fontFamily: 'amaranthregular',
    },
    body1: {
      fontSize: '0.95rem',
      lineHeight: 1.6,
      color: '#545454',
      fontFamily: 'montserratmedium',
    },
    body2: {
      fontSize: '0.95rem',
      color: '#828080',
      fontFamily: 'montserratregular',
      lineHeight: 1.6,
    },
    title: {
      fontFamily: 'montserratbold',
      fontSize: 18,
    },
    caption: {
      fontSize: '0.75rem',
      fontFamily: 'montserratregular',
    },
    fontWeightMedium: 500,
  },
});
