import React, { useContext, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { deleteQr, getQrImage } from 'context/qr/actions';
import Loading from 'components/Loading';
import QrsColumns from './QrsColumns';
import { QrsContext } from '../index';
import { toggleQrStatus } from 'context/qr/actions';
import { I18nContext } from 'translations';
import { modalMessageHtml } from 'utils';

export default function QrsTable() {
  const { translate } = useContext(I18nContext);
  const {
    filters,
    list,
    loadingQrs,
    qrStatusLoading,
    qrImageLoading,
    qrDeleteLoading,
    dispatch,
    checkin,
  } = useContext(QrsContext);
  const [qrsToApplyAction, setQrsToApplyAction] = useState([]);

  useEffect(() => {
    qrImageLoading === false && setQrsToApplyAction([]);
  }, [qrImageLoading]);

  useEffect(() => {
    qrDeleteLoading === false && setQrsToApplyAction([]);
  }, [qrDeleteLoading]);

  const messageOkDelete = (qrId) => {
    modalMessageHtml(
      '',
      translate('app.payments.qrCode.confirm.delete'),
      'warning',
      translate('confirm'),
      true,
      translate('cancel'),
      true
    ).then((response) => {
      deleteQr({
        dispatch,
        qrId,
        params: { ...filters, company_id: checkin.company._id },
      });
    });
  };

  const columns = QrsColumns({
    onDelete: (qrId) => {
      setQrsToApplyAction([...qrsToApplyAction, qrId]);
      messageOkDelete(qrId);
    },
    onDownload: (qrId) => {
      setQrsToApplyAction([...qrsToApplyAction, qrId]);
      getQrImage({
        dispatch,
        qrId,
      });
    },
    onToggle: (qrId) => {
      setQrsToApplyAction([...qrsToApplyAction, qrId]);
      toggleQrStatus({
        dispatch,
        qrId,
        params: { ...filters, company_id: checkin.company._id },
      });
    },
    isLoadingDelete: qrDeleteLoading,
    isLoadingDownload: qrImageLoading,
    isLoadingToggle: qrStatusLoading,
    qrsToApplyAction,
    t: translate,
  });

  if (loadingQrs || !list) return <Loading />;

  const listFormattedId = list.rows.map((item) => {
    return {
      ...item,
      id: item._id,
    };
  });

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid rows={listFormattedId} columns={columns} />
    </div>
  );
}
