import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { I18nContext } from 'translations';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

const QrsFilters = ({ onFiltersChange, companyChildren, filters }) => {
  const { translate } = useContext(I18nContext);
  const { handleSubmit, control, reset } = useForm({
    defaultValues: filters,
  });

  const renderTextField = (name, label) => (
    <Controller
      control={control}
      name={name}
      render={(field) => (
        <TextField
          {...field}
          label={label}
          variant="outlined"
          fullWidth
          size="small"
        />
      )}
    />
  );

  const renderSelectField = (name, label, options) => (
    <Controller
      control={control}
      name={name}
      render={({ onChange, value }) => (
        <FormControl variant="outlined" fullWidth size="small">
          <InputLabel>{label}</InputLabel>
          <Select
            fullWidth
            onChange={onChange}
            value={value}
            label={label}
            variant="outlined"
          >
            <MenuItem value="" style={{ minHeight: '3rem' }} />
            {options &&
              options.map((option) => (
                <MenuItem key={option.company_id} value={option.company_id}>
                  {option.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    />
  );

  const handleFormSubmit = (data) => {
    onFiltersChange(data);
  };

  const handleFormReset = () => {
    const resetValues = {
      name: '',
      company_id: '',
    };

    reset(resetValues);
    onFiltersChange(resetValues);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container spacing={1} style={{ padding: '1rem' }}>
        <Grid item xs={12} md={6}>
          {renderTextField(
            'name',
            translate('app.payments.qrCode.filters.name')
          )}
        </Grid>
        {companyChildren && (
          <Grid item xs={12} md={6}>
            {renderSelectField(
              'company_id',
              translate('app.payments.qrCode.create.form.client'),
              companyChildren
            )}
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1} justify="flex-end">
        <Grid item xs={12} md={2}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            {translate('search')}
          </Button>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button onClick={handleFormReset} color="primary" fullWidth>
            {translate('clear')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default QrsFilters;
