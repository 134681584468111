import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import QrsDateFilters from '../Qrs/QrsDateFilters';
import QrDetailStats from './QrDetailStats';
import QrGraph from './QrGraph';
import QrSpecificDetails from './QrSpecificDetails';
import QrBreadCrumb from './QrBreadCrumb';
import { useStateValue } from 'context/store';
import { getQr } from 'context/qr/actions';
import { useParams } from 'react-router-dom';
import Loading from 'components/Loading';

const QrDetail = () => {
  const { id } = useParams();
  const [filters, setFilters] = useState({
    initDate: null,
    endDate: null,
    filter_type: 'all',
    tags: [],
  });

  const [
    {
      checkin: { checkin },
      qrs: { qr, qrTags, qrLoading },
    },
    dispatch,
  ] = useStateValue();

  useEffect(() => {
    if (checkin) {
      getQr({
        dispatch,
        qrId: id,
        payload: {
          ...filters,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkin.company, filters]);

  if (qrLoading || !qr) return <Loading />;

  return (
    <Grid container spacing={2} style={{ padding: '2rem' }}>
      <Grid item xs={12} container spacing={2} alignItems="center">
        <QrBreadCrumb />
      </Grid>
      <Grid item xs={12}>
        <QrsDateFilters
          filters={filters}
          setFilters={setFilters}
          tagsOptions={qrTags}
          isQrDetail
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4} alignItems="stretch">
          <QrSpecificDetails qr={qr.qr_info} qr_summary={qr?.summary} />
          <Grid item xs={12} sm={7} md={9} lg={10}>
            <Grid
              container
              spacing={2}
              style={{
                height: '100%',
              }}
            >
              <QrDetailStats qr_summary={qr?.summary} isQrDetail />
              <QrGraph qrStats={qr.graph} summary={qr?.summary} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QrDetail;
