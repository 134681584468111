import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import QrsTabPanel from './QrsTabPanel';
import useQrsTabsList from './useQrsTabsList';
import { Chip, Tab } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { QrsContext } from '../../index';
import { I18nContext } from 'translations';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    background: '#FFFFFF',
    boxShadow: 'none',
    borderBottom: `1px solid ${grey[300]}`,
  },
  tab: {
    '& .MuiTab-wrapper': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row-reverse',
    },
  },
}));

const QrsTabs = () => {
  const { translate } = useContext(I18nContext);
  const { QrsTabsList } = useQrsTabsList({ t: translate });
  const classes = useStyles();
  const {
    list: { tabs },
    filters,
    setFilters,
    activeTab,
    setActiveTab,
  } = useContext(QrsContext);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const a11yProps = (index) => ({
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  });

  const InvisibleChar = '\u00A0'; // Caracter invisible

  const tabsValues = Object.values(tabs);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className={classes.appBar}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable auto tabs example"
        >
          {QrsTabsList?.map((tab, index) => (
            <Tab
              className={classes.tab}
              key={index}
              onClick={() => {
                setFilters({ ...filters, status: tab.filter });
              }}
              label={tab.title + InvisibleChar}
              icon={<Chip label={tabsValues[index]} size="small" />}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      {QrsTabsList?.map((tab, index) => (
        <QrsTabPanel key={index} value={activeTab} index={index}>
          <tab.content />
        </QrsTabPanel>
      ))}
    </div>
  );
};

export default QrsTabs;
