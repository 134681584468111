import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Container, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { I18nContext } from 'translations';
import { makeStyles } from '@material-ui/core/styles';
import Menu from 'components/Landing/Layouts/Menu';

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '85vh',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      height: '70vh',
    }
  },
  img: {
    maxWidth: '70%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50%'
    }
  }
}));

const Error = ({ match, message }) => {
  const classes = styles();
  const { translate } = useContext(I18nContext);
  return (
    <>
      {match.path === '*' && (
        <div style={{ marginTop: 70 }}>
          <Menu />
        </div>
      )}
      <div className={classes.root}>
        <Container
          maxWidth="md"
          className={classes.container}
        >
          <img
            src="/images/404.gif"
            alt="page-not-found"
            className={classes.img}
          />

          <div className="text-center">
            <Typography
              variant="h5"
              color="textSecondary"
              style={{ fontSize: '1.75rem' }}
            > 
              {message ? translate(message) : translate('message404')}
            </Typography>
            <br/><br/>
            {message ? (
              <Button
                variant="contained"
                color="primary"
                size="large"
                className="btn-rounded"
                href="/app"
              >
                {translate('tryAgain')}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="large"
                className="btn-rounded"
                component={Link}
                to={match.url === '/app' ? '/app' : '/'}
              >
                {translate('goToStart')}
              </Button>
            )}
          </div>
        </Container>
      </div>
    </>
  );
}


export default withRouter(Error);
