import React, { useState, useContext } from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  Link,
  IconButton,
  Collapse,
  Typography,
  Button
} from '@material-ui/core';
import { format } from 'date-fns';
import { I18nContext } from 'translations';
import { formatCost, booleanToString, bookingStatuses, piboxAdminRoles } from 'utils';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  PAYMENT_METHODS,
  BOOKING_STATUSES,
  ROLE_COMPANY_NAME,
} from 'utils/constants';
import ModalHoursDedicated from 'components/Modals/ModalHoursDedicated'

export default function RowBookingTable({
  booking, classes, index, isRent, company, user_id, getBookings, openClaim, role
}) {
  const [open, setOpen] = useState(false);
  const [openHoursDedicated, setOpenHoursDedicated] = useState(false);
  const [bookingSelected, setBookingSelected] = useState(null)
  const { translate } = useContext(I18nContext)

  const handleHoursDedicated = (hours, bookingId) => {
    setOpenHoursDedicated(!openHoursDedicated)
    setBookingSelected({
      hours,
      bookingId
    })
  }

  return (
    <>
      <TableRow
        hover
        key={booking._id + index + booking.created_at}
        style={{
          opacity:
            booking.status_cd === 201 || booking.status_cd === 202
              ? 0.5
              : 1,
        }}
      >
        <TableCell>
          {booking.child_bookings?.length > 0 &&
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        </TableCell>
        <TableCell className={classes.status}>
          <span
            className={bookingStatuses(booking.status_cd)}
            style={{ padding: '3px 7px' }}
          >
            {translate(BOOKING_STATUSES[booking.status_cd])}
          </span>
        </TableCell>
        <TableCell>
          <span className={classes.date}>
            {format(
              new Date(booking.created_at),
              'dd/MM/yyyy, hh:mm a'
            )}
          </span>
        </TableCell>
        <TableCell className={classes.address}>
          <Link
            href={`/bookings/${booking._id}`}
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
            underline="hover"
          >
            {booking.address}
          </Link>
        </TableCell>
        {!isRent &&
          <>
            <TableCell>
              {booking.scheduled_at ? (
                <span className={classes.date}>
                  {format(
                    new Date(booking.scheduled_at),
                    'dd/MM/yyyy, hh:mm a'
                  )}
                </span>
              ) : (
                translate('no')
              )}
              {booking.hours_dedicated &&
                <>
                {` - ${booking.hours_dedicated} ${translate(
                  'app.booking.table.hours'
                )}`}
                {([0, 1, 14, 109, 110, 120, 124].includes(booking.status_cd)) &&
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => handleHoursDedicated(booking.hours_dedicated, booking._id)}
                  >
                    {translate('edit')}
                  </Button>
                }
                </>
              }
            </TableCell>
            <TableCell>
              {booking.stops.length ? booking.stops.length : '0'}
            </TableCell>
          </>
        }
        <TableCell>
          {booking.vehicle
            ? booking.vehicle.vehicle_type?.name
            : translate('nA')}
        </TableCell>
        {!isRent &&
          <>
            <TableCell>
              {booking?.total_collected_value
                ? formatCost(booking.total_collected_value)
                : translate('nA')}
            </TableCell>
            <TableCell>
              {booking?.total_collected_value && booking?.wallet_company_collection_fee
                ? formatCost(booking.wallet_company_collection_fee)
                : translate('nA')}
            </TableCell>
          </>
        }
        <TableCell>
          {translate(PAYMENT_METHODS[booking.payment_method_cd])}
        </TableCell>
        <TableCell>
          {translate(booleanToString(booking.use_wallet_balance))}
        </TableCell>
        <TableCell>
          {booking.company_final_cost || booking.delivery_cost
            ? booking.company_final_cost
              ? formatCost(booking.company_final_cost)
              : formatCost(booking.delivery_cost)
            : booking.final_cost
            ? formatCost(booking.final_cost)
            : formatCost(booking.estimated_cost)}
        </TableCell>
        <TableCell>
          {booking.status_cd === 201 || booking.status_cd === 202 ? (
            ' - '
          ) : (
            <Link
              href={`/bookings/${booking._id}`}
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
              underline="hover"
            >
              {booking._id}
            </Link>
          )}
        </TableCell>
        {!user_id && company && (
          <TableCell>
            {ROLE_COMPANY_NAME[booking.company_area_name] !== undefined
              ? translate(ROLE_COMPANY_NAME[booking.company_area_name])
              : booking.company_area_name}
          </TableCell>
        )}
        {company && (
          <TableCell
            className={booking.cost_center ? classes.status : ''}
          >
            {booking.cost_center ? booking.cost_center.name : ' - '}
          </TableCell>
        )}
        <TableCell>
          {booking.passenger && (
            <span>{`${booking.passenger.name} - ${booking.passenger.email}`}</span>
          )}
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            size="small"
            style={{ width: '95px', borderRadius: '17px' }}
            onClick={() => openClaim(booking._id)}
            disabled={booking?.status_cd !== 4 || booking?.has_active_pqr || piboxAdminRoles(role)?.length === 0}
          >
            {translate('claims.createPQR')}
          </Button>
        </TableCell>
        {!booking.express_service && (
          <TableCell>
            <span className="tag-next-day">
              {translate('app.nextDay')}
            </span>
          </TableCell>
        )}
        <TableCell>
          <span className={`tag-${booking.get_type_of_pibox_service}`}>
            { translate(`app.${booking.get_type_of_pibox_service}`) }
          </span>
        </TableCell>
      </TableRow>
      {/* booking child */}
      <TableRow>
        {booking.child_bookings?.length > 0 &&
          <TableCell colSpan={16} style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse in={open} timeout="auto" unmountOnExit style={{ paddingBottom: 15 }}>
              <Typography variant="h6" style={{ padding: '6px 0 6px 25px' }}>
                {translate('servicesProvided')}
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>{translate('app.bookings.table.state')}</TableCell>
                    <TableCell>{translate('app.bookings.table.date')}</TableCell>
                    <TableCell>
                      {translate('app.bookings.table.originAddress')}
                    </TableCell>
                    <TableCell colSpan={1}>
                      {translate('app.bookings.table.nOfStops')}
                    </TableCell>
                    <TableCell colSpan={1}>
                      {translate('app.bookings.table.id')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                {booking.child_bookings.map(child => (
                  <TableRow key={child._id + child.created_at}>
                    <TableCell />
                    <TableCell className={classes.status} colSpan={1}>
                      <span
                        className={bookingStatuses(child.status_cd)}
                        style={{ padding: '3px 7px' }}
                      >
                        {translate(BOOKING_STATUSES[child.status_cd])}
                      </span>
                    </TableCell>
                    <TableCell colSpan={1}>
                      <span className={classes.date}>
                        {format(
                          new Date(child.created_at),
                          'dd/MM/yyyy, hh:mm a'
                        )}
                      </span>
                    </TableCell>
                    <TableCell className={classes.address} colSpan={1}>
                      <Link
                        href={`/bookings/${child._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        color="primary"
                        underline="hover"
                      >
                        {child.address}
                      </Link>
                    </TableCell>
                    <TableCell colSpan={1}>
                      {child.stops.length ? child.stops.length : '0'}
                    </TableCell>
                    <TableCell colSpan={1}>
                      {child.status_cd === 201 || child.status_cd === 202 ? (
                        ' - '
                      ) : (
                        <Link
                          href={`/bookings/${child._id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          color="primary"
                          underline="hover"
                        >
                          {child._id}
                        </Link>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </Collapse>
          </TableCell>
        }
      </TableRow>
      {openHoursDedicated &&
        <ModalHoursDedicated
          handleModal={() => setOpenHoursDedicated(!openHoursDedicated)}
          open={openHoursDedicated}
          bookingSelected={bookingSelected}
          getBookings={getBookings}
        />
      }
    </>
  )
}