import React, { useContext, useEffect, useCallback } from 'react';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Slide,
  Select,
  MenuItem,
  Typography,
  TextField,
  Snackbar,
  CircularProgress
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { formatCost } from 'utils';
import { yupResolver } from '@hookform/resolvers';
import { getBankAccountsAvailable, withdraw } from 'context/payments/actions';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { modalMessage } from 'utils';
import { I18nContext } from 'translations';
import schema from './schema';
import styles from './style';

function ModalWithdraw ({
  classes,
  open,
  setOpen,
  account
}) {
  const { langCode, translate } = useContext(I18nContext);
  const { handleSubmit,  errors, control, getValues } = useForm({
    resolver: yupResolver(schema)
  });
  const [{
    payments: { bankAccountsAvailable },
    commons: { message }},
    dispatch
  ] = useStateValue();

  const submit = async values => {
    modalMessage('', translate('app.payments.withdraw.areYouSureYouWantToWithdrawThisAmount'), 'warning', translate('accept'), true, true, translate('cancel')).then(async (status) => {
      if(status.isConfirmed) {
        const callback = await withdraw(dispatch, values.id, values.amount, langCode);
        callback.status && modalMessage(translate('app.payments.withdraw.successfulDelivery'), '', 'success', translate('accept'), true);
      }
    })
  }

  const myAccounts = useCallback(async () => {
    await getBankAccountsAvailable(dispatch);
  }, [dispatch]);

  useEffect(() => {
    myAccounts();
  }, [myAccounts]);

  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="text-center" disableTypography>
        <Typography variant="h5" color="primary">
          <small>{translate('app.payments.withdraw.picashBalance')}: </small> {account ? formatCost(account.balance) : '0'}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.content}>

        <form onSubmit={handleSubmit(submit)} noValidate>
          {bankAccountsAvailable ? (
            <FormControl fullWidth className="text-left" variant="outlined" size="small" required style={{ display: getValues('id') ? 'none' : 'flex' }}>
              <label className={classes.label}>{translate('app.payments.withdraw.whereDoYouWantToTransferTheMoney')}</label>
              <Controller
                control={control}       
                name="id"
                defaultValue=""
                error={!!errors.id}
                as={
                  <Select>
                    <MenuItem value="">
                      <em>{translate('app.payments.withdraw.choose')}</em>
                    </MenuItem>
                    {bankAccountsAvailable && bankAccountsAvailable.map(account => (
                      <MenuItem value={account._id} key={account._id}>
                        <span>
                          <img src={account.bank.logo_url} alt={account.bank.name} className={classes.logo} />
                        </span>
                        {`${account?.bank?.name} ${account.number} `}
                        {account.bank_account_type && account.bank_account_type.name}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />
              {errors.id && (
                <p className="error">
                  {translate(errors.id.message)}
                </p>
              )}
            </FormControl>
          ) : (
            <CircularProgress color="primary" />
          )}
          {getValues("id") && (
            <Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={500}>
              <FormControl required error={!!errors.amount} className={classes.amount}>
                <p><b>{translate('app.payments.withdraw.valueToWithdraw')}</b></p>
                <Controller
                  control={control}
                  name="amount"
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <NumberFormat
                      thousandSeparator
                      prefix="$"
                      customInput={TextField}
                      value={value}
                      onValueChange={(values) => {
                        const { value } = values;
                        onChange(value);
                      }}
                      placeholder={translate('app.payments.withdraw.value')}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      helperText={
                        value === ""
                          ? translate('app.payments.withdraw.enterTheValueToWithdraw')
                          : null
                      }
                      error={value === ""}
                    />
                  )}
                />
              </FormControl>
            </Slide>
          )}
          <DialogActions className={classes.buttons}>
            {getValues('id') ? (
              <Button variant="contained" color="primary" type="submit">
                {translate('app.payments.withdraw.transfer')}
              </Button>
            ) : (
              <Button variant="contained" color="primary" type="submit">
                {translate('next')}
              </Button>
            )}
            <Button onClick={() => setOpen(false)}>
              {translate('cancel')}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </Dialog>
  )
}

export default withStyles(styles)(ModalWithdraw)