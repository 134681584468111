import React, { useContext, useState, useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from '@material-ui/core';
import Scrollbar from 'react-scrollbars-custom';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import {
  getAproxAddress,
  createBooking,
  cleanOptimizeStops,
  cleanBulkLoad,
  dataStops,
  listBookingReservation,
  createBookingRent
} from 'context/express/actions';
import { cleanSalesPibox } from 'context/sales/actions';
import { cleanRouteOptimizeOps } from 'context/operations/optimizeRoutes/actions';
import HeaderForm from './FormFields/HeaderForm';
import OriginFields from './FormFields/OriginFields';
import PaymentHeaderForm from './FormFields/HeaderForm/PaymentHeaderForm';
import PaymentMethodFields from './FormFields/PaymentMethodFields';
import PaymentMethodFieldsRent from './FormFields/PaymentMethodFieldsRent';
import EstimatedValues from './FormFields/EstimatedValues';
import FormButton from './FormFields/FormButton';
import ReturnToOriginField from './FormFields/ReturnToOriginField';
import ListStops from './Stops/ListStops';
import OptionButtons from './FormFields/OptionButtons';
import { getRelatedCities } from 'context/express/actions';
import { modalMessage } from 'utils';
import styles from '../NewStyle';
import FormButtonRent from './FormFields/FormButtonRent';

const Form = ({
  classes,
  configuration,
  city,
  setCity,
  address,
  setAddress,
  setRemoveAddress,
  cleanStops,
  setCleanStops,
  setReturnToOrigin,
  origin,
  setOrigin,
  isRent,
  availableServiceType
}) => {
  const { langCode, translate } = useContext(I18nContext);
  const [expanded, setExpanded] = useState(false);
  const [showPackage, setShowPackage] = useState(false);
  const [customer, setCustomer] = useState(false);
  const [step, setStep] = useState(0);
  const [favoriteAddress, setFavoriteAddress] = useState(null);
  const [counterDelivery, setCounterDelivery] = useState(false);
  const [driverWithMoneyBalance, setDriverWithMoneyBalance] = useState(false);
  const [loadingAproxAddress, setLoadingAproxAddress] = useState(false);
  const [checkStops, setCheckStops] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [bookingReservationId, setBookingReservationId] = useState(null);
  let isChecked = [];
  const [
    {
      bookings: {
        quote,
        loadingQuote,
        bulkLoad,
        loadingBulkLoad,
        optimize,
        relatedCities,
        totalWeight,
        promoCode,
        loadingPromoCode
      },
      optimizeRoutes: { routeOptimizeOps },
      sales: { saleList },
      checkin: { company, balance_wallet },
    },
    dispatch,
  ] = useStateValue();

  const {
    handleSubmit,
    register,
    control,
    formState,
    errors,
    reset,
    watch,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: { stops: [] },
  });
  const { isValid } = formState;
  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: 'stops',
  });

  useEffect(() => {
    resetForm();
    setCity(null);
  }, [langCode, dispatch]); // eslint-disable-line

  useEffect(() => {
    if (bulkLoad && bulkLoad.length > 0) {
      fields.shift();
      setExpanded('close');
      setAddress([]);
      reset({ 
        secondary_address: getValues('secondary_address'),
        cost_center_id: getValues('cost_center_id'),
        vehicle_types: getValues('vehicle_types'),
        vehicle: getValues('vehicle'),
        stops: [...bulkLoad]
      });
    }
    return () => cleanBulkLoad(dispatch);
  }, [bulkLoad]); // eslint-disable-line

  useEffect(() => {
    if (optimize && optimize.length > 0) {
      setExpanded('close');
      setAddress([]);
      reset({
        secondary_address: getValues('secondary_address'),
        cost_center_id: getValues('cost_center_id'),
        vehicle_types: getValues('vehicle_types'),
        vehicle: getValues('vehicle'),
        stops: [...optimize]
      });
      setCleanStops(false);
    }
    return () => cleanOptimizeStops(dispatch);
  }, [optimize]); // eslint-disable-line

  // Show the stops that come from the sales table
  useEffect(() => {
    if (saleList && saleList.length > 0 && origin && origin.lat) {
      setExpanded('close');
      setAddress([]);
      reset({ stops: [...saleList] });
    }
  }, [saleList, origin]); // eslint-disable-line

  useEffect(() => {
    return () => cleanSalesPibox(dispatch);
  }, [saleList, dispatch]);

  // Show stops coming from optimized routes
  useEffect(() => {
    if (
      routeOptimizeOps &&
      routeOptimizeOps.length > 0 &&
      origin &&
      origin.lat
    ) {
      setExpanded('close');
      setAddress([]);
      reset({ stops: [...routeOptimizeOps] });
    }
  }, [routeOptimizeOps, origin]); // eslint-disable-line

  useEffect(() => {
    return () => cleanRouteOptimizeOps(dispatch);
  }, [routeOptimizeOps, dispatch]);

  const resetForm = () => {
    reset();
    setAddress([]);
    setExpanded('origin');
    setOrigin(null);
    setStep(0);
    reset({ stops: [] });
  };

  // Add a stop to the form
  const addStops = () => {
    append({});
    setExpanded(`stops${fields.length}`);
  };

  // Delete a stop
  const removeStops = async (index, id) => {
    remove(index);
    setAddress((prevAddress) => prevAddress.filter((item, i) => i !== index));
    setRemoveAddress(id);
    setExpanded('close');
    setShowPackage(false);
    setCustomer(false);
    setCheckStops(false);
  };

  // Order the stops
  const moveStops = async (e, index, indexEnd) => {
    e.stopPropagation();
    setAddress((prevAddress) => {
      const updateAddress = [...prevAddress];
      updateAddress[index] = prevAddress[indexEnd];
      updateAddress[indexEnd] = prevAddress[index];
      return updateAddress;
    });
    swap(index, indexEnd);
    setCheckStops(false);
  };

  const driverWithMoney = () =>
    fields.some(
      (j, i) => watch(`stops[${i}].packages[0].counter_delivery`) === true
    );

  const handleChange = (panel) => (event, isExpanded) => {
    panel === 'origin' && setDriverWithMoneyBalance(driverWithMoney());
    setCheckStops(false);
    setExpanded(isExpanded ? panel : false);
  };

  const handleSuggest = (info) => setOrigin(info);

  // Get the address of the stops
  const handleAddress = (info, index, id) => {
    setAddress((prevAddres) => {
      const updateRoutes = [...prevAddres];
      updateRoutes[index] = { ...info, id };
      return updateRoutes;
    });
  };

  // Get an address, according to the point in the marker
  const changeCoord = async (coord, type, index, id) => {
    setLoadingAproxAddress(true);
    const callback = await getAproxAddress(dispatch, coord);
    if (callback.aproxAddress) {
      setLoadingAproxAddress(false);
      if (type === 1) {
        const updateAddress = [...address];
        const actualAddress = {
          ...address[index],
          name: callback.aproxAddress,
          lat: coord.lat,
          lon: coord.lng,
        };
        if (updateAddress[index]) {
          updateAddress[index] = { ...address[index], ...actualAddress };
          setAddress(updateAddress);
        } else {
          const addAddress = [...address, { ...actualAddress, id }];
          setAddress(addAddress);
        }
      } else {
        setOrigin({
          name: callback.aproxAddress,
          lat: coord.lat,
          lon: coord.lng,
        });
      }
    } else {
      setLoadingAproxAddress(false);
    }
  };

  const submit = async (values, type) => {
    if (isRent) {
      await createBookingRent(
        dispatch,
        { origin, address, values, promoCode },
        type > 0 ? type : 0,
        langCode,
      );
    } else {
      if (type === 3) {
        const exportStops = await dataStops({ address, values }, translate);
        setExportData(exportStops);
      } else {
        const isMultiplier = getValues(['multiplier']);
        if (typeof type === 'object' && isMultiplier?.multiplier !== '' && isMultiplier?.multiplier !== '1') {
          modalMessage(
            translate('messageWarningMultiplier'),
            translate('messageConfirmMultiplier'),
            'warning',
            translate('continue'),
            true,
            true,
            translate('cancel'),
            true
          ).then(async (result) => {
            if (result.isConfirmed) {
              await createBooking(
                dispatch,
                { origin, address, values, moneyBalance: counterDelivery },
                type > 0 ? type : 0,
                langCode,
                saleList,
                bookingReservationId,
                routeOptimizeOps
              );
            }
          });
        } else {
          await createBooking(
            dispatch,
            { origin, address, values, moneyBalance: counterDelivery },
            type > 0 ? type : 0,
            langCode,
            saleList,
            bookingReservationId,
            routeOptimizeOps
          );
        }
      }
    }
  };

  // Choose a favorite direction and show it in origin and stops
  const getFavoriteAddresses = useCallback(() => {
    if (expanded !== false && expanded !== 'close') {
      if (expanded === 'origin') {
        return setOrigin(favoriteAddress);
      } else {
        const index = expanded.toString().replace(/^\D+/g, '');
        return setAddress((prevAddres) => {
          const updateRoutes = [...prevAddres];
          updateRoutes[index] = { ...favoriteAddress, id: fields[index].id };
          return updateRoutes;
        });
      }
    }
  }, [favoriteAddress, setAddress, setOrigin]); // eslint-disable-line

  useEffect(() => {
    favoriteAddress && getFavoriteAddresses();
  }, [getFavoriteAddresses, favoriteAddress]);

  // Get the related cities
  const listRelatedCities = useCallback(
    async (originCity) => {
      await getRelatedCities(dispatch, langCode, originCity);
    },
    [langCode, dispatch]
  );

  useEffect(() => {
    const originCity = city ? city : configuration.cities_for_delivery[0];
    langCode && listRelatedCities(originCity);
  }, [langCode, listRelatedCities, city, configuration.cities_for_delivery]);

  const selectedPrincipalBooking = (id) => {
    setBookingReservationId(id);
    id &&
      modalMessage(
        '',
        translate('app.bookingReserver.messageBookingReservation'),
        'success',
        'Continue'
      );
  };

  const bookReservations = async () => {
    const callback = await listBookingReservation(dispatch);
    callback && setReservations(callback.reservations);
  };

  useEffect(() => {
    bookReservations();
  }, []); // eslint-disable-line

  return (
    <form onSubmit={handleSubmit(submit)} autoComplete="off" noValidate>
      <div style={{ display: step === 0 ? 'block' : 'none' }}>
        <HeaderForm
          cities={
            configuration.cities_for_delivery.length > 0
              ? configuration.cities_for_delivery
              : [{ id: 'none', name: translate('app.express.noCities') }]
          }
          city={city}
          resetForm={resetForm}
          setCity={setCity}
          address={address}
          expanded={expanded}
          setFavoriteAddress={setFavoriteAddress}
          isRent={isRent}
        />
        <Scrollbar className="scroll-form">
          <Accordion
            expanded={expanded === 'origin'}
            onChange={handleChange('origin')}
            className={classes.accordionContainer}
          >
            <AccordionSummary
              expandIcon={<EditIcon color="primary" fontSize="small" />}
              disabled={expanded !== 'close' && expanded !== 'paymentRent'}
            >
              <Typography>
                <span className="tag-rounded" style={{ marginRight: 10 }}>
                  1
                </span>
                {expanded === 'origin'
                  ? translate('app.express.origin')
                  : origin && origin.name
                  ? origin.name
                  : translate('app.express.origin')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <OriginFields
                origin={origin}
                handleSuggest={handleSuggest}
                register={register}
                city={{
                  lon: city
                    ? city.location.lon
                    : configuration.cities_for_delivery.length > 0
                    ? configuration.cities_for_delivery[0].location.lon
                    : 0,
                  lat: city
                    ? city.location.lat
                    : configuration.cities_for_delivery.length > 0
                    ? configuration.cities_for_delivery[0].location.lat
                    : 0,
                  name: city
                    ? city.name
                    : configuration.cities_for_delivery.length > 0
                    ? configuration.cities_for_delivery[0].name
                    : translate('app.express.nameCouldNotBeFound'),
                  id: city
                    ? city._id
                    : configuration.cities_for_delivery.length > 0
                    ? configuration.cities_for_delivery[0]._id
                    : 0,
                }}
                type={0}
                changeCoord={changeCoord}
                loadingAproxAddress={loadingAproxAddress}
                Controller={Controller}
                control={control}
                counterDelivery={counterDelivery}
                driverWithMoneyBalance={driverWithMoneyBalance}
                company={company && company}
                vehicleTypes={watch('vehicle_types')}
                listVehicles={
                  configuration?.freight_services
                    ? configuration.freight_services
                    : null
                }
                isRent={isRent}
                availableServiceType={availableServiceType}
                favoritesAsOrigins={configuration?.use_favorites_as_origins}
                moneyBalance={watch('requires_a_driver_with_base_money')}
              />
            </AccordionDetails>
          </Accordion>
          {loadingBulkLoad ? (
            <div style={{ padding: '10px 20px' }}>
              <CircularProgress size={30} />
            </div>
          ) : (
            <ListStops
              fields={fields}
              expanded={expanded}
              handleChange={handleChange}
              origin={origin}
              address={address}
              moveStops={moveStops}
              removeStops={removeStops}
              handleAddress={handleAddress}
              Controller={Controller}
              register={register}
              errors={errors}
              city={city}
              configuration={configuration}
              changeCoord={changeCoord}
              loadingAproxAddress={loadingAproxAddress}
              customer={customer}
              setCustomer={setCustomer}
              counterDelivery={counterDelivery}
              control={control}
              showPackage={showPackage}
              isChecked={isChecked}
              checkStops={checkStops}
              cities={relatedCities?.length > 0 && relatedCities}
              company={company}
              isRent={isRent}
            />
          )}
          {expanded === 'close' &&
            address.length < 100 &&
            !checkStops &&
            !(saleList && saleList[0]?.is_shopify) && !isRent && (
              <div>
                <AccordionSummary
                  expandIcon={<AddIcon color="primary" />}
                  onClick={addStops}
                >
                  <Typography color="primary" variant="subtitle2">
                    {translate('app.express.addRoute')}
                  </Typography>
                </AccordionSummary>
              </div>
            )
          }
          {!cleanStops && !isRent && (
            <div
              className={classes.linePadding}
              style={{
                display: expanded === 'close' ? 'block' : 'none',
                visibility: checkStops ? 'hidden' : 'visible',
              }}
            >
              <ReturnToOriginField
                register={register}
                numberMap={watch('return_to_origin') && address.length + 2}
                returnToOrigin={watch('return_to_origin')}
                setReturnToOrigin={setReturnToOrigin}
              />
            </div>
          )}
          {isRent &&
            <div style={{ visibility: expanded === 'paymentRent' ? 'visible' : 'hidden' }}>
              <Accordion
                expanded={expanded === 'paymentRent'}
                onChange={handleChange('paymentRent')}
                className={classes.accordionContainer}
              >
                <AccordionSummary
                  expandIcon={<EditIcon color="primary" fontSize="small" />}
                  disabled={expanded !== 'close'}
                >
                  <Typography>
                    <span className="tag-rounded" style={{ marginRight: 10 }}>3</span>
                    {translate('app.express.paymentMethod')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.flexColumn}>
                  <PaymentMethodFieldsRent
                    Controller={Controller}
                    control={control}
                    typeServices={watch('type_services')}
                    scheduledAt={watch('scheduled_at')}
                    company={company}
                    selectedPrincipalBooking={selectedPrincipalBooking}
                    listReservations={reservations}
                    bookingReservationId={bookingReservationId}
                    dispatch={dispatch}
                    loadingPromoCode={loadingPromoCode}
                    promoCode={promoCode}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          }
        </Scrollbar>
      </div>

      <div style={{ display: step === 1 ? 'block' : 'none' }}>
        <PaymentHeaderForm setStep={setStep} />
        <div style={{ padding: '50px 30px 0' }} className={classes.scroll}>
          {address.length > 0 && (
            <PaymentMethodFields
              Controller={Controller}
              control={control}
              register={register}
              typeServices={watch('type_services')}
              scheduledAt={watch('scheduled_at')}
              company={company}
              selectedPrincipalBooking={selectedPrincipalBooking}
              listReservations={reservations}
              bookingReservationId={bookingReservationId}
              balance_wallet={balance_wallet}
            />
          )}
        </div>
      </div>
      <div className={classes.footerForm}>
        {step === 0 && !isRent ? (
          <OptionButtons
            reset={reset}
            setAddress={setAddress}
            setExpanded={setExpanded}
            expanded={expanded}
            setCleanStops={setCleanStops}
            setCheckStops={setCheckStops}
            isChecked={isChecked}
            checkStops={checkStops}
            moveStops={moveStops}
            fields={fields}
            handleSubmit={handleSubmit}
            submit={submit}
            exportData={exportData}
          />
        ) : (
          <EstimatedValues
            quote={quote}
            loadingQuote={loadingQuote}
            totalWeight={totalWeight}
            isRent={isRent}
          />
        )}
        {isRent ?
          <FormButtonRent
            expanded={expanded}
            setExpanded={setExpanded}
            fields={fields}
            origin={origin}
            addStops={addStops}
            isValid={isValid}
            step={step}
            setStep={setStep}
            setCustomer={setCustomer}
            paymentMethod={watch('payment_method')}
            handleSubmit={handleSubmit}
            submit={submit}
            loadingPromoCode={loadingPromoCode}
          />
        :
          <FormButton
            expanded={expanded}
            setExpanded={setExpanded}
            fields={fields}
            address={address}
            origin={origin}
            addStops={addStops}
            showPackage={showPackage}
            setShowPackage={setShowPackage}
            isValid={isValid}
            step={step}
            setStep={setStep}
            setCustomer={setCustomer}
            paymentMethod={watch('payment_method')}
            handleSubmit={handleSubmit}
            submit={submit}
            moneyBalance={watch('requires_a_driver_with_base_money')}
            setCounterDelivery={setCounterDelivery}
            serviceByHours={watch('hours_dedicated')}
            scheduledAt={watch('scheduled_at')}
            vehicleTypes={watch('vehicle_types')}
          />
        }
      </div>
    </form>
  );
};

export default withStyles(styles)(Form);
