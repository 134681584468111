import { string, object } from 'yup';

const generateSchema = ({ isPhysical }) => {
  const schema = {
    qr_name: string().required('requiredField'),
    city: string().required('requiredField'),
    tags: string().required('requiredField'),
  };

  if (isPhysical) {
    schema.delivery_address = string().required('requiredField');
    schema.quantity = string().required('requiredField');
  }

  return object().shape(schema);
};

export default generateSchema;
