import React from 'react';
import QrPhysicalConfirm from './QrPhysicalConfirm';
import QrDigitalConfirm from './QrDigitalConfirm';

const QrConfirm = ({ watch, company, isPhysical }) => {
  return isPhysical ? (
    <QrPhysicalConfirm watch={watch} company={company} />
  ) : (
    <QrDigitalConfirm watch={watch} company={company} />
  );
};

export default QrConfirm;
