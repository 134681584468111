import { Button, CircularProgress, Grid } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { I18nContext } from 'translations';
import DeliveryForm from './DeliveryForm';
import { QrModalContext } from '../QrModal';
import DesignSelector from './DesignForm';
import QrConfirm from './QrConfirm';
import QrStepper from './QrStepper';
import { useForm } from 'react-hook-form';
import generateSchema from './DeliverySchema';
import InitialForm from './InitialForm';
import initialFormSchema from './InitialFormSchema';

const STEPS = [
  {
    content: (props) => <InitialForm {...props} />,
  },
  {
    label: 'Completa la información',
    content: (props) => <DeliveryForm {...props} />,
  },
  {
    label: 'Selecciona tu código',
    content: (props) => <DesignSelector {...props} />,
  },
  {
    label: 'Finaliza tu solicitud',
    content: (props) => <QrConfirm {...props} />,
  },
];

const QrForm = ({ handleFormSubmit, company, companyChildren, loading }) => {
  const { translate: t } = useContext(I18nContext);
  const { handleClose } = useContext(QrModalContext);
  const [activeStep, setActiveStep] = useState(0);
  const [isPhysical, setIsPhysical] = useState(false);
  const [qrData, setQrData] = useState({
    client: '',
    qr_type: 0,
    qr_design: 0,
    qr_name: '',
    department: '',
    city: '',
    tags: [],
    delivery_address: '',
    quantity: 1,
  });

  const handleNext = () => {
    if (activeStep === 1 && !isPhysical) {
      setActiveStep(activeStep + 2);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 2 && !isPhysical) {
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
    } else if (activeStep === 0) handleClose();
    else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const { handleSubmit, watch, control, errors, getValues, setError } = useForm(
    {
      defaultValues: qrData,
      shouldUnregister: false,
    }
  );

  const validateStep0 = () => {
    const fieldsToValidate = getValues(['client', 'qr_type']);
    initialFormSchema
      .validate(fieldsToValidate, { abortEarly: false })
      .then(() => handleNext())
      .catch((error) => {
        error.inner.forEach((error) => {
          setError(error.path, { message: error.message });
        });
      });
    const isPhysical = fieldsToValidate.qr_type === 1;
    setIsPhysical(isPhysical);

    setQrData((prev) => ({
      ...prev,
      client: fieldsToValidate.client,
      qr_type: fieldsToValidate.qr_type,
    }));
  };

  const validateStep1 = () => {
    const fieldsToValidate = getValues([
      'qr_name',
      'city',
      'tags',
      'delivery_address',
      'quantity',
    ]);
    const deliveryFormShema = generateSchema({ isPhysical });
    deliveryFormShema
      .validate(fieldsToValidate, { abortEarly: false })
      .then(() => handleNext())
      .catch((error) => {
        error.inner.forEach((error) => {
          setError(error.path, { message: error.message });
        });
      });
    setQrData((prev) => ({
      ...prev,
      qr_name: fieldsToValidate.qr_name,
      department: fieldsToValidate.department,
      city: fieldsToValidate.city,
      tags: fieldsToValidate.tags,
      delivery_address: fieldsToValidate.delivery_address,
      quantity: fieldsToValidate.quantity,
    }));
  };

  const submit = (values) => {
    switch (activeStep) {
      case 0:
        validateStep0(
          getValues,
          setError,
          handleNext,
          setIsPhysical,
          setQrData
        );
        break;
      case 1:
        validateStep1(getValues, setError, handleNext, setQrData);
        break;
      case 2:
        handleNext();
        break;
      default:
        handleFormSubmit(values, handleClose);
    }
  };

  return (
    <>
      {activeStep > 0 && isPhysical && (
        <QrStepper steps={STEPS} activeStep={activeStep - 1} />
      )}
      <form onSubmit={handleSubmit(submit)}>
        {STEPS[activeStep].content({
          control,
          errors,
          watch,
          isPhysical,
          company,
          companyChildren,
        })}
        <Grid
          container
          spacing={2}
          justify="flex-end"
          style={{
            marginTop: '1rem',
          }}
        >
          <Grid item xs={6} sm={2}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleBack}
              disabled={loading}
            >
              {activeStep === 0 ? t('close') : t('back')}
            </Button>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Button
              startIcon={loading && <CircularProgress size={18} />}
              disabled={loading}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="small"
            >
              {activeStep === STEPS.length - 1 ? t('send') : t('next')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default QrForm;
