import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  useTheme,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { I18nContext } from 'translations';
import Subtitle from './Subtitle';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CityAutoComplete from 'components/CustomInputs/CityAutoComplete';
import QrTagsSelector from './QrTagsSelector';

const DeliveryForm = ({ control, errors, isPhysical }) => {
  const { translate } = useContext(I18nContext);
  const theme = useTheme();
  return (
    <>
      <Subtitle
        text={translate('app.payments.qrCode.create.addressForm.title')}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="qr_name"
            render={({ onChange, value }) => (
              <TextField
                fullWidth
                label={translate('app.payments.qrCode.create.form.qr_name')}
                variant="outlined"
                size="small"
                value={value}
                onChange={onChange}
                error={!!errors.qr_name}
                helperText={errors.qr_name && translate(errors.qr_name.message)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="city"
            render={(field) => (
              <CityAutoComplete
                {...field}
                error={!!errors.qr_name}
                helperText={errors.qr_name && translate(errors.qr_name.message)}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="tags"
            render={(field) => (
              <QrTagsSelector
                {...field}
                label={translate('app.payments.qrCode.create.form.tags')}
                size="small"
                error={!!errors.tags}
                helperText={errors.tags && translate(errors.tags.message)}
              />
            )}
          />
        </Grid>
        {isPhysical && (
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="delivery_address"
              render={({ onChange, value }) => (
                <TextField
                  fullWidth
                  label={translate(
                    'app.payments.qrCode.create.form.delivery_address'
                  )}
                  variant="outlined"
                  size="small"
                  value={value}
                  onChange={onChange}
                  error={!!errors.delivery_address}
                  helperText={errors.delivery_address?.message}
                />
              )}
            />
          </Grid>
        )}
        {isPhysical && (
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="quantity"
              render={({ onChange, value }) => (
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel htmlFor="input-amount">
                    {translate('app.payments.qrCode.create.form.quantity')}
                  </InputLabel>
                  <OutlinedInput
                    id="input-amount"
                    fullWidth
                    label={translate(
                      'app.payments.qrCode.create.form.quantity'
                    )}
                    variant="outlined"
                    size="small"
                    value={value}
                    readOnly
                    onChange={onChange}
                    error={!!errors.quantity}
                    helperText={errors.quantity?.message}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton
                          style={{
                            background: theme.palette.primary.main,
                            color: theme.palette.getContrastText(
                              theme.palette.primary.main
                            ),
                          }}
                          color="primary"
                          size="small"
                          onClick={() =>
                            value > 1 && onChange(parseInt(value) - 1)
                          }
                        >
                          <RemoveIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          style={{
                            background: theme.palette.primary.main,
                            color: theme.palette.getContrastText(
                              theme.palette.primary.main
                            ),
                          }}
                          color="primary"
                          size="small"
                          onClick={() =>
                            value < 15 && onChange(parseInt(value) + 1)
                          }
                        >
                          <AddIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              )}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DeliveryForm;
