import React, { useState, useContext, useEffect } from 'react'
import { Paper, Grid, Typography, Container } from '@material-ui/core'
import { useQuery } from '@apollo/client';
import SearchIcon from '@material-ui/icons/Search';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@material-ui/icons/UnarchiveOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import LocalShippingTwoToneIcon from '@material-ui/icons/LocalShippingTwoTone';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { piboxAdminRolesCompany } from 'utils';
import { GET_ANALYTICS } from 'graphql/analytics/queries';
import Title from 'components/Titles';
import AnalyticsCard from 'components/Cards/AnalyticsCard';
import BarGraph from 'components/Analytics/BarGraph';
import LineGraph from 'components/Analytics/LineGraph';
import PieGraph from 'components/Analytics/PieGraph';
import Loading from 'components/Loading';
import AnalyticsFilters from './Filters';
import MessageInfo from 'components/Modals/MessageInfo';

export default function Analytics ({ history }) {
  const { translate } = useContext(I18nContext);
  const [{ checkin: { checkin } }] = useStateValue();
  const [filters, setFilters] = useState({ adminUser: piboxAdminRolesCompany(checkin?.profile?.roles).length === 1 ? true : false });
  const { loading, error, data, refetch } = useQuery(GET_ANALYTICS, {
    variables: { ...filters }
  });
  const [state, setState] = useState({
    openSync: true,
    vertical: 'top',
    horizontal: 'right'
  });

  const { vertical, horizontal, openSync } = state;

  const onFilters = (params) => {
    setFilters(params)
  }

  useEffect(() => {
    let interval = null;
    if (data?.analytics?.data_recreation) {
      interval = setInterval(() => {
        refetch()
      }, 60000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  },[data]) //eslint-disable-line

  const handleClose = () => {
    setState({ ...state, openSync: false });
  };

  const goToPage = () => history.push('/app');

  if (error) return <p>{error.message}</p>;  

  if (data?.analytics?.data_recreation) {
    return (
      <MessageInfo
        vertical={vertical}
        horizontal={horizontal}
        openSync={openSync}
        handleClose={handleClose}
        history={history}
        title={translate('app.sync.analytics.title')}
        text={translate('app.sync.analytics.text')}
        buttonText={translate('analytics.goToHome')}
        goToPage={goToPage}
      />
    )
  } else if (loading && Object.keys(filters).length === 1) {
    return (
      <MessageInfo
        vertical={vertical}
        horizontal={horizontal}
        openSync={openSync}
        handleClose={handleClose}
        history={history}
        title={translate('app.sync.analytics.title')}
        text={translate('app.sync.analytics.text')}
        buttonText={translate('analytics.goToHome')}
        goToPage={goToPage}
      />
    )
  }
  
  return (
    <div style={{ background: '#fafafa', paddingBottom: 15 }}>
      <Title
        title={translate('analytics.title')}
        icon={<AssessmentOutlinedIcon className="align-center" style={{ marginRight: 5 }} />}
        subtitle={translate('analytics.subtitle')}
      />
      <div className="marginFiltersTable">
        <AnalyticsFilters onFilters={onFilters}/>
      </div>
      {(loading && Object.keys(filters).length > 1) && <Loading />}
      <Paper style={{ padding: '25px', borderRadius: 12, marginBottom: 25 }} elevation={0}>
        <Grid container spacing={4}>
          <Grid item md={7} sm={12} xs={12}>
            <BarGraph
              title={translate('analytics.activeServicesByLocation')}
              textBottom={translate('analytics.numberOfServices')}
              bookings={data?.analytics?.charts?.company_area_group.map(total => total.bookings > 0 ? total.bookings : null)?.filter(booking => booking)}
              names={data?.analytics?.charts?.company_area_group.map(total => total.bookings > 0 ? total.name : null)?.filter(name => name)}
            />
          </Grid>
          <Grid item md={5} sm={12} xs={12}>
            <Grid container spacing={2} style={{ marginTop: 20 }}>
              <Grid item xs={6}>
                <AnalyticsCard
                  title={translate('analytics.card.lookingForDriver')}
                  count={data?.analytics?.charts?.booking_statuses_data?.looking_for_driver}
                  icon={<SearchIcon color="primary" fontSize="large" style={{ marginTop: '9px' }} />}
                />
              </Grid>
              <Grid item xs={6}>
                <AnalyticsCard
                  title={translate('analytics.card.pickingUpPackages')}
                  count={data?.analytics?.charts?.booking_statuses_data?.picking_up_package}
                  icon={<ArchiveOutlinedIcon color="primary" fontSize="large" style={{ marginTop: '9px' }} />}
                />
              </Grid>
              <Grid item xs={6}>
                <AnalyticsCard
                  title={translate('analytics.card.deliveringPackages')}
                  count={data?.analytics?.charts?.booking_statuses_data?.delivering_package}
                  icon={<UnarchiveOutlinedIcon color="primary" fontSize="large" style={{ marginTop: '9px' }} />}
                />
              </Grid>
              <Grid item xs={6}>
                <AnalyticsCard
                  title={translate('analytics.card.driverOnTheWay')}
                  count={data?.analytics?.charts?.booking_statuses_data?.driver_en_route}
                  icon={<LocalShippingOutlinedIcon color="primary" fontSize="large" style={{ marginTop: '9px' }} />}
                />
              </Grid>
              <Grid item xs={6}>
                <AnalyticsCard
                  title={translate('analytics.card.packagesOnBoard')}
                  count={data?.analytics?.charts?.booking_statuses_data?.package_on_board}
                  icon={<LocalShippingTwoToneIcon color="primary" fontSize="large" style={{ marginTop: '9px' }} />}
                />
              </Grid>
              <Grid item xs={6}>
                <AnalyticsCard
                  title={translate('analytics.card.completedServices')}
                  count={data?.analytics?.charts?.booking_statuses_data?.finished_by_driver}
                  icon={<CheckBoxOutlinedIcon color="primary" fontSize="large" style={{ marginTop: '9px' }} />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper style={{ padding: '25px', borderRadius: 12, marginBottom: 25 }} elevation={0}>
        <Container maxWidth="md">
          <Grid container className="text-center" spacing={2}>
            <Grid item md={4} sm={6} xs={12}>
              <Typography color="primary" variant="h5">
                {data?.analytics?.charts?.booking_statuses_data?.canceled_by_passenger}
              </Typography>
              <Typography>
                {translate('analytics.canceledByPassenger')}
              </Typography>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Typography color="primary" variant="h5">
                {data?.analytics?.charts?.booking_statuses_data?.expred}
              </Typography>
              <Typography>
                {translate('analytics.expired')}
              </Typography>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Typography color="primary" variant="h5">
                {data?.analytics?.charts?.booking_statuses_data?.finished_by_driver}
              </Typography>
              <Typography>
                {translate('analytics.finishedByDriver')}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Grid container spacing={2} style={{ marginBottom: 15 }}>
        <Grid item md={4} sm={6} xs={12}>
          <Paper style={{ padding: '15px 10px', borderRadius: 12 }} elevation={0}>
            {data?.analytics.graphs && 
              <LineGraph
                title={`${translate('analytics.services')}:`}
                textLeft={translate('analytics.quantity')}
                pointRadius={2}
                total={data.analytics.graphs?.map(total => (
                  total.total_bookings
                ))}
                dates={data.analytics.graphs?.map(date => (
                  date.timestamp
                ))}
                isSum={true}
              />
            }
          </Paper>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Paper style={{ padding: '15px 10px', borderRadius: 12 }} elevation={0}>
            <LineGraph
              title={`${translate('analytics.stops')}:`}
              textLeft={translate('analytics.quantity')}
              pointRadius={2}
              total={data?.analytics?.graphs?.map(total => (
                total.total_stops
              ))}
              dates={data?.analytics?.graphs?.map(date => (
                date.timestamp
              ))}
              isSum={true}
            />
          </Paper>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Paper style={{ padding: '15px 10px', borderRadius: 12 }} elevation={0}>
            <LineGraph
              title={`${translate('analytics.packages')}:`}
              textLeft={translate('analytics.quantity')}
              pointRadius={2}
              total={data?.analytics?.graphs?.map(total => (
                total.total_packages
              ))}
              dates={data?.analytics?.graphs?.map(date => (
                date.timestamp
              ))}
              isSum={true}
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: 15 }}>
        <Grid item md={6} sm={6} xs={12}>
          <Paper style={{ padding: '15px 10px', borderRadius: 12 }} elevation={0}>
            <LineGraph
              title={translate('analytics.consumedAccordingToThePeriod')}
              textLeft={translate('analytics.consumption')}
              pointRadius={2}
              total={data?.analytics?.graphs?.map(total => (
                total.total_txns
              ))}
              dates={data?.analytics?.graphs?.map(date => (
                date.timestamp
              ))}
              isSum={false}
              tooltipMssg={translate('analytics.mssgTxns')}
            />
          </Paper>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          {data?.analytics?.charts?.counter_delivery_data.require_counter_delivery > 0 &&
            <Paper style={{ padding: '15px 10px', borderRadius: 12 }} elevation={0}>
              <PieGraph
                title={translate('analytics.collections')}
                info={data?.analytics?.charts?.counter_delivery_data}
              />
            </Paper>
          }
        </Grid>
      </Grid>
    </div>
  )
}