import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Snackbar, Switch, Grid } from '@material-ui/core';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { getBalances, setStateBalance, getTransactionBalance } from 'context/balances/actions';
import { I18nContext } from 'translations';
import Loading from 'components/Loading';
import Title from 'components/Titles';
import ListTransactionsTable from 'components/Tables/Balances/ListTransactionsTable';
import { INITIAL_PAGE, PER_PAGE, RENT } from 'utils/constants';
import { modalMessage } from 'utils';
import CardBalance from './CardBalance';

export default function PaymentBalances() {
  const { translate } = useContext(I18nContext);
  const [per_page] = useState(PER_PAGE);
  const [loadingisActive, setLoadingIsActive] = useState(false);

  const [
    {
      checkin: { company },
      commons: { message },
      balances: { list, loadingBalances, listTransactions, listTransactionsLoading }
    }, dispatch
  ] = useStateValue();
  const [isActive, setIsActive] = useState(false);
  const [isActiveSwitch, setIsActiveSwitch] = useState(false);

  const isRent = process.env.REACT_APP_API_URL_BASE_IS_RENT === RENT;

  const getListBalances = useCallback(
    async (page) => {
      getBalances(dispatch, company._id, {
        page,
        per_page
      });
    },
    [dispatch, per_page, company]
  );

  const getListTransactions = useCallback(
    async (page) => {
      getTransactionBalance(dispatch, company?._id, { page, per_page });
    },
    [dispatch, per_page, company]
  );

  useEffect(() => {
    company && getListBalances(INITIAL_PAGE)
  }, [getListBalances]) // eslint-disable-line

  useEffect(() => {
    company && getListTransactions(INITIAL_PAGE)
  }, [getListTransactions]) // eslint-disable-line

  const messageOK = (mssg) => {
    modalMessage(
      false,
      translate(mssg),
      'success',
      translate('close'),
      true
    )
    setLoadingIsActive(false)
  }
  
  const handleChange = async (event) => {
    setLoadingIsActive(true);
    const newIsActive = event.target.checked;
    setIsActive(newIsActive);
    const callback = await setStateBalance(dispatch, {is_activate:  newIsActive}, company._id);
    callback?.status && newIsActive === true ? messageOK('balances.balance.transactions.isActiveOK') : messageOK('balances.balance.transactions.isDisabledOK')
  };
  
  const getStateBalance = useCallback(
    async () => {
      if(list['is_active']){
        if (list['total_balance']['subunits'] > 0){
          setIsActive(true);
          setIsActiveSwitch(false);
        }else{
          setIsActive(false);
          setIsActiveSwitch(true);
        }
      }else{
        setIsActive(false);
        setIsActiveSwitch(false);
      }
    },
    [list]
  );

  useEffect(() => {
    list && getStateBalance()
  }, [getStateBalance]) // eslint-disable-line

  return (
    <div style={{ background: '#fafafa' }}>
      {loadingBalances && <Loading />}
      {listTransactionsLoading && <Loading />}
      {loadingisActive && <Loading />}
      <Grid container spacing={1}>
        {list &&
          Object.keys(list)
            .filter(key => key !== 'is_active' && key !== 'default')
            .map((key) => {
              if (!isRent && key !== 'picap') {
                return <CardBalance key={key} list={list} type={key} />
              } else if (isRent) {
                return <CardBalance key={key} list={list} type={key} />
              } return null
            }    
          )
        }
      </Grid> 

      <div className="padding-page-full-height" style={{ background: 'white' }}>
        <Title
          title={translate('balances.balance.transactions.title')}
          subtitle={translate('balances.balance.transactions.description')}
        />
        <Switch
          checked={Boolean(isActive)}
          onChange={handleChange}
          color="primary"
          disabled={isActiveSwitch}
        />
        {listTransactions &&
          <ListTransactionsTable
            data={listTransactions}
            getListTransactions={getListTransactions}
            recordsPerPage={per_page}
          />
        }
      </div>

      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </div>
  );
}
