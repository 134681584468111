import React, { useContext } from 'react';
import { Grid, Paper } from '@material-ui/core';
import QrLineGraph from 'components/Analytics/QrLineGraph';
import { I18nContext } from 'translations';

const QrGraph = ({ qrStats, summary }) => {
  const { translate } = useContext(I18nContext);
  const tags = Object.entries(summary)?.map((tag) => tag[0]);
  const dataset = tags.reduce((acc, tag) => {
    acc[tag] = qrStats.map((scans) => scans[tag]);
    return acc;
  }, {});
  const dates = [...new Set(qrStats.map((scans) => scans.date))];

  return (
    <Grid item xs={12}>
      <Paper
        style={{
          padding: '15px 10px',
          borderRadius: '1rem',
          boxShadow: '0px 1px 2px 0px #0000000F, 0px 1px 3px 0px #0000001A',
        }}
        elevation={0}
      >
        <QrLineGraph
          title={translate('app.payments.qrCode.graph.title')}
          textLeft=""
          pointRadius={2}
          datasets={dataset}
          dates={dates}
        />
      </Paper>
    </Grid>
  );
};

export default QrGraph;
