import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { useCardStyles } from './styles';

const QrDesignSelectorCard = ({
  classes,
  onClick: onclick,
  src,
  key,
  value,
}) => (
  <Paper
    onClick={onclick}
    elevation={0}
    className={`${classes} ${value === key && classes.setSelectedDesign}`}
  >
    <Grid container spacing={3} justify="center">
      <img src={src} alt="QR Code" style={{ width: '100%' }} />
    </Grid>
  </Paper>
);

const QrDesignSelector = ({
  designOptions,
  value,
  onChange,
  setSelectedDesign,
}) => {
  const cardStyles = useCardStyles();
  return (
    <Grid container spacing={4} alignItems="stretch">
      {designOptions.map((design, index) => (
        <Grid item xs={12} md={4} key={index}>
          <QrDesignSelectorCard
            {...design}
            classes={cardStyles.option}
            value={value}
            onClick={() => {
              onChange(design.key);
              setSelectedDesign(design.key);
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default QrDesignSelector;
