import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Container, Typography } from '@material-ui/core';
import { I18nContext } from 'translations';
import Loading from 'components/Loading';
import { makeStyles } from '@material-ui/core/styles';
import { isAddonEnabled, hasNoRoles } from 'utils';

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '85vh',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      height: '70vh',
    },
  },
  img: {
    maxWidth: '70%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50%',
    },
  },
}));

const AccessDenied = ({ children, company, loading, roles, isAddon }) => {
  const classes = styles();
  const { translate } = useContext(I18nContext);

  if (loading) {
    return <Loading />;
  }

  if (
    isAddon
      ? isAddonEnabled(company?.enabled_addons, isAddon)
      : hasNoRoles(company, roles || [])
  ) {
    return children;
  }

  return (
    <>
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <img
            src="/images/404.gif"
            alt="page-not-found"
            className={classes.img}
          />

          <div className="text-center">
            <Typography
              variant="h5"
              color="textSecondary"
              style={{ fontSize: '1.75rem' }}
            >
              {translate('accessDeniedMessage')}
            </Typography>
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              size="large"
              className="btn-rounded"
              href="/app"
            >
              {translate('goToStart')}
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
};

export default withRouter(AccessDenied);
