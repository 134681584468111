import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { I18nContext } from 'translations';
import QrTypeSelector from './QrTypeSelector';
import Subtitle from './Subtitle';

const InitialForm = ({ control, errors, company, companyChildren }) => {
  const { translate } = useContext(I18nContext);

  const clientList = companyChildren;

  return (
    <>
      <Subtitle
        text={translate('app.payments.qrCode.create.validateNextInfo')}
      />
      <Grid container style={{ padding: '1rem 0' }}>
        <Grid item xs={12}>
          <Typography variant="body2">
            <strong>
              {translate('app.payments.qrCode.create.validateNextInfo.client')}:{' '}
            </strong>
            {company?.business_name}
          </Typography>
          <Typography variant="body2">
            <strong>
              {translate(
                'app.payments.qrCode.create.validateNextInfo.socialReason'
              )}
              :{' '}
            </strong>
            {company?.client_name}
          </Typography>
          <Typography variant="body2">
            <strong>
              {translate(
                'app.payments.qrCode.create.validateNextInfo.documentType'
              )}
              :{' '}
            </strong>{' '}
            {company?.document_type}
          </Typography>
          <Typography variant="body2">
            <strong>
              {translate(
                'app.payments.qrCode.create.validateNextInfo.documentNumber'
              )}
              :{' '}
            </strong>
            {company?.fiscal_number}
          </Typography>
          <Typography variant="body2">
            <strong>
              {translate(
                'app.payments.qrCode.create.validateNextInfo.walletId'
              )}
              :{' '}
            </strong>
            {company?.wallet_account_id}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="client"
            render={({ onChange, value }) => (
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                error={errors.client}
              >
                <InputLabel error={!!errors.bank_id}>
                  {translate('app.payments.qrCode.create.form.client')}
                </InputLabel>
                <Select
                  fullWidth
                  onChange={onChange}
                  value={value}
                  label={translate('app.payments.qrCode.create.form.client')}
                  variant="outlined"
                  error={Boolean(errors?.client)}
                >
                  <MenuItem value="" style={{ minHeight: '3rem' }} />
                  {clientList &&
                    clientList.map((client) => (
                      <MenuItem
                        key={client.company_id}
                        value={client.company_id}
                      >
                        {client.name}
                      </MenuItem>
                    ))}
                </Select>
                {errors.client && (
                  <FormHelperText error>
                    {translate(errors.client.message)}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Subtitle text={translate('app.payments.qrCode.create.selectQrType')} />
        <Grid item xs={12}>
          <Controller
            control={control}
            name="qr_type"
            render={({ onChange, value }) => (
              <QrTypeSelector value={value} onChange={onChange} />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default InitialForm;
