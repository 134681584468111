import React, { useEffect, useContext, useCallback, useState } from 'react';
import { Snackbar, Button, Grid, IconButton } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import HeaderCreditCards from 'components/Headers/HeaderCreditCards';
import ModalCreditCard from 'components/Modals/ModalCreditCard';
import Loading from 'components/Loading';
import { INITIAL_PAGE, PER_PAGE, COUNTRY_CODE } from 'utils/constants';
import {
  getCreditCards,
  getDocumentTypeList,
  removeCreditCard,
} from 'context/payments/actions';
import DeleteIcon from '@material-ui/icons/Delete';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import SwipeableViews from 'react-swipeable-views';
import styles from './style';
import { Pagination } from '@material-ui/lab';
import { modalMessage } from 'utils';

export default function CreditCards() {
  const { langCode, translate } = useContext(I18nContext);
  const [open, setOpen] = useState(false);
  const [per_page] = useState(PER_PAGE);
  const [creditCardSelected, setCreditCardSelected] = useState(null);

  const [
    {
      payments: { creditCards, creditCardsLoading, documentTypeList },
      commons: { message },
      checkin: { company },
    },
    dispatch,
  ] = useStateValue();

  const getCards = useCallback(
    async (page) => {
      await getCreditCards(dispatch, { page, per_page });
    },
    [dispatch, per_page]
  );

  const getConfigCreditCard = useCallback(async () => {
    const geo_fence_id = company
      ? company.geo_fence_id
      : COUNTRY_CODE[langCode][0];
    getDocumentTypeList(dispatch, { geo_fence_id });
  }, [dispatch, company, langCode]);

  useEffect(() => {
    getCards(INITIAL_PAGE);
  }, [getCards, getConfigCreditCard]);

  const showRemoveCreditCardConfirmation = (creditCard) => {
    modalMessage(
      '',
      translate('app.payments.bankAccount.youWantToDeleteThisCreditCard'),
      'warning',
      translate('accept'),
      true,
      true,
      translate('cancel')
    ).then(async (status) => {
      if (status.isConfirmed) {
        const callback = await removeCreditCard(dispatch, creditCard._id);
        callback.status && getCards(INITIAL_PAGE);
      }
    });
  };

  const [index, setIndex] = useState(1);
  const PAGINATION_DIFF = 1;
  const FILL_NUMBERS = '************';

  const isThereCards = creditCards?.cards?.length > 0;

  return (
    <div style={{ background: '#fafafa' }}>
      {creditCardsLoading && <Loading />}
      <HeaderCreditCards />
      {isThereCards && (
        <Grid container justify="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setCreditCardSelected(null);
              setOpen(true);
            }}
          >
            {translate('app.payments.creditCard.addCreditCard')}
          </Button>
        </Grid>
      )}
      {open && (
        <ModalCreditCard
          open={open}
          setOpen={setOpen}
          creditCardList={creditCards}
          // accountTypeList={accountTypeList}
          documentTypeList={documentTypeList}
          getCreditCards={() => getCards(INITIAL_PAGE)}
          creditCardSelected={creditCardSelected}
        />
      )}
      <Grid container style={{ height: 'calc(100vh - 240px)' }}>
        {isThereCards && (
          <Grid container item justify="flex-end" style={{ padding: '1rem 0' }}>
            <Pagination
              count={creditCards.cards.length}
              color="primary"
              page={index}
              onChange={(e, value) => setIndex(value)}
            />
          </Grid>
        )}
        {isThereCards ? (
          <SwipeableViews
            enableMouseEvents
            style={{ height: 'calc(100vh - 280px)', width: '100%' }}
            index={index - PAGINATION_DIFF}
            onChangeIndex={(index) => {
              setIndex(index + PAGINATION_DIFF);
            }}
          >
            {creditCards.cards?.map((card, index) => (
              <Grid
                key={`${card?._id}${index}`}
                container
                justify="center"
                alignItems="center"
                direction="column"
                style={{ height: 'calc(100vh - 300px)' }}
              >
                <Cards
                  className={styles.card || ''}
                  name={card?.card_name || ''}
                  number={`${FILL_NUMBERS}${card?.number}` || ''}
                  preview={true}
                  issuer={card.bin_info?.scheme || ''}
                  cvc={''}
                  expiry={''}
                  placeholders={{
                    name: '',
                    number: '',
                    cvc: '',
                    expiry: '',
                  }}
                  locale={{
                    valid: translate('app.payments.creditCards.valid'),
                  }}
                />
                <Grid
                  container
                  justify="space-evenly"
                  spacing={1}
                  style={{ margin: '1rem 0' }}
                >
                  <IconButton
                    aria-label="Delete"
                    onClick={() => showRemoveCreditCardConfirmation(card)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </SwipeableViews>
        ) : (
          <Grid container justify="center" alignItems="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setCreditCardSelected(null);
                setOpen(true);
              }}
            >
              {translate('app.payments.creditCard.addCreditCard')}
            </Button>
          </Grid>
        )}
      </Grid>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </div>
  );
}
