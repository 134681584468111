import { Grid, Typography, colors, useTheme } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useContext } from 'react';
import Subtitle from './Subtitle';
import { useCardStyles } from './styles';
import DesignPreview from './DesignPreview';
import { I18nContext } from 'translations';

const QrPhysicalConfirm = ({ watch, company }) => {
  const { translate } = useContext(I18nContext);
  const cardStyles = useCardStyles();
  const selectedDesign = watch('qr_design');

  const theme = useTheme();

  return (
    <Grid container spacing={2}>
      <Subtitle
        text={translate(
          'app.payments.qrCode.create.confirmationPhysical.title'
        )}
      />
      <Grid item xs={12} sm={8}>
        <Grid container className={cardStyles.option} justify="center">
          <Typography variant="body2">
            {translate(
              'app.payments.qrCode.create.confirmationPhysical.description'
            )}
          </Typography>
          <ul>
            <li>
              <Typography variant="caption">
                {translate(
                  'app.payments.qrCode.create.confirmationPhysical.name'
                )}
                : <strong>{watch('qr_name')}</strong>
              </Typography>
            </li>
            <li>
              <Typography variant="caption">
                {translate(
                  'app.payments.qrCode.create.confirmationPhysical.quantity'
                )}
                : <strong>{watch('quantity')}</strong>
              </Typography>
            </li>
            <li>
              <Typography variant="caption">
                {translate(
                  'app.payments.qrCode.create.confirmationPhysical.address'
                )}
                : <strong>{watch('delivery_address')}</strong>
              </Typography>
            </li>
            <li>
              <Typography variant="caption">
                {translate(
                  'app.payments.qrCode.create.confirmationPhysical.city'
                )}
                : <strong>{watch('city').name}</strong>
              </Typography>
            </li>
            <li>
              <Typography variant="caption">
                {translate(
                  'app.payments.qrCode.create.confirmationPhysical.tags'
                )}
                : {watch('tags').join(', ')}
              </Typography>
            </li>
          </ul>
          <Alert
            severity="info"
            style={{
              backgroundColor: '#D7CFFB',
              color: theme.palette.getContrastText('#D7CFFB'),
            }}
          >
            <AlertTitle>Info</AlertTitle>
            <Typography variant="caption">
              {translate(
                'app.payments.qrCode.create.confirmationPhysical.message'
              )}
            </Typography>
          </Alert>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        style={{
          padding: '1rem',
          borderRadius: '1rem',
          border: `2px dotted ${colors.grey[300]}`,
        }}
      >
        <DesignPreview design={selectedDesign} />
      </Grid>
    </Grid>
  );
};

export default QrPhysicalConfirm;
