import React from 'react';
import {
  Chip,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  withStyles,
} from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { purple } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils';

const PurpleSwitch = withStyles({
  switchBase: {
    color: purple[300],
    '&$checked': {
      color: purple[500],
    },
    '&$checked + $track': {
      backgroundColor: purple[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const QrsColumns = ({
  onDelete,
  onDownload,
  onToggle,
  isLoadingDelete,
  isLoadingDownload,
  isLoadingToggle,
  qrsToApplyAction,
  t,
}) => [
  {
    field: 'name',
    headerName: t('app.payments.qrCode.column.name'),
    minWidth: 180,
    flex: 1,
    renderCell: ({ row: { id, name } }) => (
      <Link to={`qrs/${id}`} style={{ color: purple[500] }}>
        {name}
      </Link>
    ),
  },
  {
    field: 'created_at',
    headerName: t('app.payments.qrCode.column.created'),
    minWidth: 150,
    flex: 1,
    renderCell: ({ row: { created_at } }) => formatDate(created_at),
  },
  {
    field: 'status_cd',
    headerName: t('app.payments.qrCode.column.status'),
    minWidth: 150,
    flex: 1,
    renderCell: ({ row: { status } }) =>
      status ? (
        <Chip label={'Activo'} style={{ background: '#D1FAE5' }} />
      ) : (
        <Chip label={'Pausado'} color="secondary" />
      ),
  },
  {
    field: 'wallet_id',
    headerName: t('app.payments.qrCode.column.wallet'),
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'tags',
    headerName: t('app.payments.qrCode.column.tags'),
    minWidth: 250,
    flex: 1,
    renderCell: ({ row: { tags } }) => (
      <Container style={{ display: 'flex', gap: 2 }}>
        {Array.isArray(tags) ? (
          tags?.map((tag, index) => <Chip key={index} label={tag} />)
        ) : (
          <Chip label={tags} />
        )}
      </Container>
    ),
  },
  {
    field: 'actions',
    headerName: t('app.payments.qrCode.column.actions'),
    minWidth: 220,
    flex: 1,
    renderCell: ({ row: { id, status } }) => (
      <Grid container justify="space-evenly">
        <Grid item>
          {isLoadingDelete && qrsToApplyAction.includes(id) ? (
            <CircularProgress size={'1.2rem'} />
          ) : (
            <IconButton onClick={() => onDelete(id)}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          )}
        </Grid>
        <Grid item>
          {isLoadingDownload && qrsToApplyAction.includes(id) ? (
            <CircularProgress size={'1.2rem'} />
          ) : (
            <IconButton onClick={() => onDownload(id)}>
              <GetAppOutlinedIcon />
            </IconButton>
          )}
        </Grid>
        <Grid item>
          {isLoadingToggle && qrsToApplyAction.includes(id) ? (
            <CircularProgress size={'1.2rem'} />
          ) : (
            <FormControlLabel
              control={
                <PurpleSwitch
                  checked={status}
                  onChange={() => onToggle(id)}
                  name="checkedA"
                />
              }
            />
          )}
        </Grid>
      </Grid>
    ),
  },
];

export default QrsColumns;
