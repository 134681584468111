import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuIcon from '@material-ui/icons/Menu';
import { useStateValue } from 'context/store';
import { listCheckin, getShopify } from 'context/checkin/actions';
import { closeSession } from 'context/users/actions';
import withHelmet from 'utils/withHelmet';
import { I18nContext } from 'translations';
import { ADDONS, COUNTRY_CODE, RENT } from 'utils/constants';
import Loading from 'components/Loading';
import LateralMenu from './Menus/LateralMenu';
import UserMenu from './Menus/UserMenu';
import Error from 'components/Error';
import styles from './style';
import ModalTermsAndConditions from 'components/Modals/ModalTermsAndConditions';
import { useHistory } from 'react-router-dom';
import { isAddonEnabled } from 'utils';

const isRent = process.env.REACT_APP_API_URL_BASE_IS_RENT === RENT;

const Layout = ({ children, classes }) => {
  const isLoggedIn = localStorage.getItem('session_token');
  const [open, setOpen] = useState(true);
  const { langCode } = useContext(I18nContext);
  let location = useLocation();
  const [
    {
      checkin: { checkin, message, loading, hasShopify, company },
      session: { session },
    },
    dispatch,
  ] = useStateValue();

  const screen = (media) => (media.matches ? setOpen(false) : setOpen(true));
  const handleToggle = () => setOpen(!open);
  const close = () => closeSession(dispatch);

  useEffect(() => {
    let media = window.matchMedia('(max-width: 960px)');
    screen(media);
    media.addListener(screen);

    const getCheckin = async () =>
      await listCheckin(dispatch, { country_id: COUNTRY_CODE[langCode][0] });
    getCheckin();
  }, [dispatch, langCode]); // eslint-disable-line

  useEffect(() => {
    getShopify(dispatch);
  }, []); // eslint-disable-line

  let history = useHistory();

  useEffect(() => {
    isRent && company &&
      isAddonEnabled(company.enabled_addons, ADDONS[6]) &&
      history.push('/app/payments/qrs');
    //eslint-disable-next-line
  }, [company]);

  const search = location.search.split('?')[1];
  const query = new URLSearchParams(location.search);

  if (!isLoggedIn) {
    return (
      <Redirect
        exact
        to={query.get('shp_uuid') ? `/signup?${search}` : '/login'}
      />
    );
  }

  return (
    <div className={classes.root}>
      {loading && <Loading />}
      {checkin && <ModalTermsAndConditions checkin={checkin} />}
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        color="inherit"
      >
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <IconButton
            onClick={handleToggle}
            className={classes.menuButton}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <UserMenu
            closeSession={close}
            profile={checkin && checkin.profile}
            company={checkin && checkin.company}
            aproxCountry={session && session.aprox_country_id}
          />
        </Toolbar>
      </AppBar>

      <LateralMenu
        open={open}
        company={checkin && checkin.company}
        hasShopify={hasShopify}
        isRent={isRent}
        profile={checkin && checkin.profile}
      />

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.bodyPage}>
          {message ? <Error message={message} /> : checkin && children}
        </div>
      </main>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  withHelmet({
    title: isRent ? 'Picap Rent' : 'Envíos y Mensajería Urbana | Pibox',
    description: isRent
      ? 'Picap Rent'
      : 'Realiza envíos de paquetes y carga a todo el país',
    image: isRent
      ? '/faviconPicap.png'
      : 'https://pibox.app/images/landing/cargo.png',
    noIndex: true,
    favicon: isRent ? '/faviconPicap.png' : '/favicon.ico',
  })(Layout)
);
