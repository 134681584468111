import {
  GO_TO_FILE,
  LIST_SALES,
  CLEAN_SALES,
  CREATE_PIBOX,
  LIST_SALES_LOADING,
  LIST_SHOPIFY_STORES,
  CREATE_PIBOX_SHOPIFY,
  LIST_WOOCOMMERCE_STORES,
  LIST_MERCADOFLEX_STORES,
} from './constants';
import { flatFormat } from 'utils';

const salesReducer = (state, action) => {
  switch (action.type) {
    case LIST_SALES_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case LIST_SALES:
      return {
        ...state,
        list: {
          ...action.list,
          data:
            state.list && action.list.page > 1
              ? state.list.data.concat(action.list.data)
              : action.list.data,
        },
      };
    case LIST_SHOPIFY_STORES:
      return {
        ...state,
        listShopifyStores: action.listShopifyStores,
      };
    case LIST_WOOCOMMERCE_STORES:
      return {
        ...state,
        listWooCommerceStores: action.listWooCommerceStores,
      };
    case LIST_MERCADOFLEX_STORES:
      return {
        ...state,
        listMercadoFlexStores: action.listMercadoFlexStores,
      };
    case CLEAN_SALES:
      return {
        ...state,
        list: null,
      };
    case CREATE_PIBOX:
      const sale =
        action.saleList &&
        action.saleList.map((sl) => {
          return {
            address: sl.destination.address,
            lat: sl.destination.lat,
            lon: sl.destination.lon,
            secondary_address: sl.destination.secondary_address || '',
            customer: {
              name: sl.customer?.name || '',
              phone: sl.customer?.phone
                ? sl.customer?.phone.split(' ').join('')
                : '',
            },
            packages: [
              {
                delivery_sale_id: sl.id,
                indications: sl.product_name || '',
                size_cd: 1,
                reference: sl.external_id || sl._id,
                declared_value: sl.product_price
                  ? flatFormat({
                      sub_units: sl.product_price.subunits,
                      iso: sl.product_price.iso,
                    })
                  : 0,
              },
            ],
          };
        });
      return {
        ...state,
        saleList: sale,
      };
    case CREATE_PIBOX_SHOPIFY:
      const saleShopify =
        action.saleList &&
        action.saleList.map((sl) => {
          return {
            is_shopify: true,
            external_id: sl.external_id,
            address: sl.destination.address,
            lat: sl.destination.lat,
            lon: sl.destination.lon,
            secondary_address: sl.destination.secondary_address || sl.destination.secondary || '',
            customer: {
              name: sl.customer?.name || sl.destination.buyer_name || '',
              phone: sl.customer?.phone
                ? sl.customer?.phone.split(' ').join('')
                : sl.destination.buyer_phone
                ? sl.destination.buyer_phone.replace(/[^0-9\.]+/g, "") // eslint-disable-line
                : ''
            },
            packages: [
              {
                delivery_sale_id: null,
                indications: sl.integration === 'mercadoflex' ? 'mercadoflex.mssg' : sl.product_name || '',
                size_cd: 1,
                reference: sl.integration === 'mercadoflex' ? sl.external_id : sl.order_id || sl._id,
                declared_value: sl.product_price
                  ? flatFormat({
                      sub_units: sl.product_price.subunits,
                      iso: sl.product_price.iso === 'USD' ? 'COP' : sl.product_price.iso,
                    })
                  : 0,
              },
            ],
          };
        });
      return {
        ...state,
        saleList: saleShopify,
        originSale: {
          name:
            action.saleList?.[0] && action.saleList[0].origin
              ? action.saleList[0].origin.address
              : '',
          lat:
            action.saleList?.[0] && action.saleList[0].origin
              ? action.saleList[0].origin.lat
              : '',
          lon:
            action.saleList?.[0] && action.saleList[0].origin
              ? action.saleList[0].origin.lon
              : '',
        },
      };
      case GO_TO_FILE:
        return {
          ...state,
          fileUrl: action.fileUrl
        };
    default:
      return state;
  }
};

export default salesReducer;
