import { Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { I18nContext } from 'translations';

const useStyles = makeStyles((theme) => ({
  stat: {
    borderRadius: '1rem',
    boxShadow: '0px 1px 2px 0px #0000000F, 0px 1px 3px 0px #0000001A',
    height: '100%',
    padding: '1.2rem 1.5rem',
  },
  title: {
    color: theme.palette.text.secondary,
  },
  Subtitle: {
    color: theme.palette.text.bold,
  },
}));

const QrStat = ({ title, value, isQrDetail }) => {
  const { translate } = useContext(I18nContext);
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6} md={4} lg={isQrDetail ? 4 : 3}>
      <Paper className={classes.stat}>
        <Typography variant="subtitle1" className={classes.title}>
          {translate(`app.payments.qrCode.stats.title.${title}`)}
        </Typography>
        <Typography variant="h4" className={classes.Subtitle}>
          {value}
        </Typography>
      </Paper>
    </Grid>
  );
};

export default QrStat;
