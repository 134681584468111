import React, { useContext, useEffect, useRef, useState } from 'react';
import 'date-fns';
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Controller, useForm } from 'react-hook-form';
import QrsFilterType from './QrsFilterType';
import { I18nContext } from 'translations';
import esLocale from 'date-fns/locale/es';
import { isDeepEqual } from 'utils';

const filtersRestObject = {
  initDate: null,
  endDate: null,
  filter_type: 'all',
};

const QrsDateFilters = ({ filters, setFilters, tagsOptions, isQrDetail }) => {
  const { translate } = useContext(I18nContext);

  const { watch, control, reset, setError, errors } = useForm({
    defaultValues: filters,
  });

  const [tagsSelected, setTagsSelected] = useState(filters.tags || []);

  const formValues = watch();

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (!isDeepEqual(formValues, filters))
      if (
        (formValues.initDate && formValues.endDate) ||
        (formValues.initDate === null && formValues.endDate === null)
      ) {
        setFilters({ ...filters, ...formValues });
      } else {
        if (!formValues.initDate)
          setError('initDate', {
            message: translate('app.payments.qrCode.errors.initialDate'),
          });
        else {
          setError('endDate', {
            message: translate('app.payments.qrCode.errors.finalDate'),
          });
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formValues.filter_type,
    formValues.initDate,
    formValues.endDate,
    formValues.tags,
    tagsSelected,
  ]);

  const resetFilters = () => {
    if (isQrDetail) {
      reset({ ...filtersRestObject, tags: [] });
    } else {
      reset({ ...filtersRestObject, name: '', company_id: '', status: '' });
    }
  };

  return (
    <Grid container spacing={2} alignItems="center" justify="flex-end">
      <Grid item xs={12} md={2}>
        <Controller
          name="filter_type"
          control={control}
          render={(field) => <QrsFilterType {...field} />}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Controller
          name="initDate"
          control={control}
          render={(field) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                {...field}
                size="small"
                fullWidth
                inputVariant="outlined"
                id="date-picker-dialog"
                label={translate('app.payments.qrCode.filters.initialDate')}
                format="MM/dd/yyyy"
                error={!!errors.initDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          )}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Controller
          name="endDate"
          control={control}
          render={(field) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                {...field}
                size="small"
                fullWidth
                inputVariant="outlined"
                id="date-picker-dialog"
                label={translate('app.payments.qrCode.filters.finalDate')}
                error={!!errors.endDate}
                format="MM/dd/yyyy"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          )}
        />
      </Grid>
      {isQrDetail && (
        <Grid item xs={12} md={2}>
          <Controller
            name="tags"
            control={control}
            render={({ onChange }) => (
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>
                  {translate('app.payments.qrCode.filters.tags')}
                </InputLabel>
                <Select
                  value={tagsSelected}
                  onChange={({ target: { value } }) => setTagsSelected(value)}
                  onClose={() => onChange(tagsSelected)}
                  multiple
                  fullWidth
                  label={translate('app.payments.qrCode.filters.tags')}
                  variant="outlined"
                  renderValue={(selected) => selected.join(', ')}
                >
                  {tagsOptions?.map((tag) => (
                    <MenuItem key={tag} value={tag}>
                      <Checkbox checked={tagsSelected.includes(tag)} />
                      <ListItemText primary={tag} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
      )}
      <Grid item xs={12} md={1}>
        <Button
          size="small"
          onClick={resetFilters}
          variant="contained"
          color="primary"
          fullWidth
        >
          {translate('clear')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default QrsDateFilters;
