import React, { useContext, useState } from 'react';
import { Grid, colors } from '@material-ui/core';
import Subtitle from './Subtitle';
import { Controller } from 'react-hook-form';
import { I18nContext } from 'translations';
import QrDesignSelector from './DesignSelector';
import DesignPreview from './DesignPreview';

const DesignSelector = ({ company, control, watch }) => {
  const { translate } = useContext(I18nContext);

  const currentSelectedDesign = watch('qr_design');

  const [selectedDesign, setSelectedDesign] = useState(currentSelectedDesign);

  const QrDesigns = [
    {
      key: 0,
      src: '/images/qr/template_0.png',
    },
    {
      key: 1,
      src: '/images/qr/template_1.png',
    },
    {
      key: 2,
      src: '/images/qr/template_2.png',
    },
  ];

  return (
    <Grid container spacing={2}>
      <Subtitle
        text={translate('app.payments.qrCode.create.form.design.title')}
      />
      <Grid item xs={12} sm={8}>
        <Controller
          control={control}
          name="qr_design"
          render={({ onChange, value }) => (
            <QrDesignSelector
              title={company.name}
              designOptions={QrDesigns}
              value={value}
              onChange={onChange}
              selectedDesign={selectedDesign}
              setSelectedDesign={setSelectedDesign}
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        style={{
          padding: '1rem',
          borderRadius: '1rem',
          border: `2px dotted ${colors.grey[300]}`,
        }}
      >
        <DesignPreview design={selectedDesign} />
      </Grid>
    </Grid>
  );
};

export default DesignSelector;
