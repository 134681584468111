import React, { useContext, useEffect, useState } from 'react';
import { Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import QrIcon from 'components/Icons/Qr';
import PhoneIcon from 'components/Icons/Phone';
import { I18nContext } from 'translations';

const useStyles = makeStyles((theme) => ({
  option: {
    height: '100%',
    padding: '2rem',
    borderRadius: '1rem',
    boxShadow:
      '0px 1px 10px 0px #0000001F,0px 4px 5px 0px #00000024, 0px 2px 4px -1px #00000033',
    border: `2px solid transparent`,
    transition: '1s',
  },
  selected: {
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: `0px 1px 10px 0px ${theme.palette.primary.main},0px 4px 5px 0px #00000024, 0px 2px 4px -1px #00000033`,
  },
}));

const QrTypeSelectorCard = ({
  image: Image,
  title,
  description,
  classes,
  selected,
  onClick: onclick,
}) => {
  const { translate } = useContext(I18nContext);
  return (
    <Paper
      onClick={onclick}
      elevation={0}
      className={`${classes.option} ${selected ? classes.selected : ''}`}
    >
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} container justify="center">
          <Image />
        </Grid>
        <Grid item xs={12} md={6} container justify="center">
          <Typography variant="subtitle1">{translate(title)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">{translate(description)}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

const CARDS_OPTIONS = [
  {
    image: () => <PhoneIcon size={'4rem'} />,
    title: 'app.payments.qrCode.create.selectQrType.digital.title',
    description: 'app.payments.qrCode.create.selectQrType.digital.description',
    selected: true,
  },
  {
    image: () => <QrIcon size={'4rem'} />,
    title:
      'app.payments.qrCode.create.selectQrType.print.physical&digital.title',
    description:
      'app.payments.qrCode.create.selectQrType.print.physical&digital.description',
  },
];

const QrTypeSelector = ({ value, onChange }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    onChange(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <Grid container spacing={4} alignItems="stretch">
      {CARDS_OPTIONS.map((card, index) => (
        <Grid item xs={12} sm={6} key={index}>
          <QrTypeSelectorCard
            {...card}
            key={index}
            selected={selected === index}
            onClick={() => setSelected(index)}
            classes={classes}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default QrTypeSelector;
