import React from 'react';
import { Grid } from '@material-ui/core';
import QrStat from './QrStat';

const QrsStats = ({ stats, isQrDetail }) => {
  return (
    <Grid container spacing={1} alignItems="stretch">
      {stats.map((stat, index) => {
        if (isQrDetail && index === 0) return null;
        return (
          <QrStat
            key={index}
            title={stat.label}
            value={stat.value}
            isQrDetail={isQrDetail}
          />
        );
      })}
    </Grid>
  );
};

export default QrsStats;
