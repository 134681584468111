import AWS from 'aws-sdk';
import { uploadVideoToApi } from 'api/requests';
import { chatTemporaryFiles } from 'api';

export const configureS3 = () => {
  AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-east-1:54033641-88fa-491a-93e7-2d276f2fce78'
    })
  });
};

export const uploadFile = async (file, path) => {
  try {
    configureS3();

    const MAX_SIZE_IMAGE_PDF = 5 * 1024 * 1024;
    const MAX_SIZE_VIDEO = 50 * 1024 * 1024;

    const fileType = file.type.split('/')[0];
    const isVideo = fileType === 'video';

    if ((isVideo && file.size > MAX_SIZE_VIDEO) || (!isVideo && file.size > MAX_SIZE_IMAGE_PDF)) {
      throw new Error(`File size exceeds the allowed limit: ${isVideo ? '50MB for videos' : '5MB for images/PDFs'}`);
    }

    if (isVideo) {
      const apiResponse = await uploadVideoToApi(chatTemporaryFiles, file);
      return apiResponse;
    }

    const upload = new AWS.S3.ManagedUpload({
      partSize: isVideo ? 10 * 1024 * 1024 : undefined,
      queueSize: 2,
      params: {
        ACL: 'public-read',
        ContentType: file.type,
        Bucket: 'static.pibox.app',
        Key: `${path}/${file.name}`,
        Body: file
      }
    });

    upload.on('httpUploadProgress', (progress) => {
      console.log(`📤 Uploaded ${progress.loaded} of ${progress.total} bytes`);
    });

    const result = await upload.promise();
    return result;
  } catch (error) {
    console.error("Error uploading the file:", error.message || error);

    if (error.message.includes('CORS')) {
      console.error("⚠️ Check your CORS configuration.");
    }

    throw error; 
  }
};
