import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import Tour from 'reactour';
import { I18nContext } from 'translations';

const stepStyle = {
  color: '#666',
  background: '#fff'
};

const TourMenu = ({ isTourOpen, setIsTourOpen, commodate }) => {
  const { translate } = useContext(I18nContext);

  const steps = [
    {
      selector: '.tour.home',
      content: () => (
        <div>
          <Typography variant='subtitle1'> {translate("tour.welcome")}</Typography>
          <p style={{ margin: '5px 0 0' }}>{translate("tour.welcome.text")}</p>
        </div>
      ),
      style: stepStyle
    },
    {
      selector: '.tour.service',
      content: () => (
        <div>
          <Typography variant='subtitle1'>{translate("tour.service")}</Typography>
          <p style={{ margin: '5px 0 0' }}>
            {translate("tour.service.text")}
          </p>
        </div>
      ),
      style: stepStyle,
    },
    {
      selector: '.tour.reports',
      content: () => (
        <div>
          <Typography variant='subtitle1'>{translate("tour.reports")}</Typography>
          <p style={{ margin: '5px 0 0' }}>
            {translate("tour.reports.text")}
          </p>
        </div>
      ),
      style: stepStyle
    },
    {
      selector: '.tour.payments',
      content: () => (
        <div>
          <Typography variant='subtitle1'>{translate("tour.payments")}</Typography>
          <p style={{ margin: '5px 0 0' }}>
            {translate("tour.payments.text")}
          </p>
        </div>
      ),
      style: stepStyle
    },
    {
      selector: '.tour.integrations',
      content: () => (
        <div>
          <Typography variant='subtitle1'>{translate("tour.integrations")}</Typography>
          <p style={{ margin: '5px 0 0' }}>
            {translate("tour.integrations.text")}
          </p>
        </div>
      ),
      style: stepStyle
    },
    {
      selector: '.tour.routes',
      content: () => (
        <div>
          <Typography variant='subtitle1'>{translate("tour.routes")}</Typography>
          <p style={{ margin: '5px 0 0' }}>
            {translate("tour.reports.text")}
          </p>
        </div>
      ),
      style: stepStyle
    },
    {
      selector: '.tour.prepackages',
      content: () => (
        <div>
          <Typography variant='subtitle1'>{translate("tour.prepackages")}</Typography>
          <p style={{ margin: '5px 0 0' }}>
            {translate("tour.prepackages.text")}
          </p>
        </div>
      ),
      style: stepStyle
    },
    {
      selector: '.tour.commodate',
      content: () => (
        <div>
          <Typography variant='subtitle1'>{translate("tour.commodate")}</Typography>
          <p style={{ margin: '5px 0 0' }}>
            {translate("tour.commodate.text")}
          </p>
        </div>
      ),
      style: stepStyle
    },
    {
      selector: '.tour.claims',
      content: () => (
        <div>
          <Typography variant='subtitle1'>{translate("tour.claims")}</Typography>
          <p style={{ margin: '5px 0 0' }}>
            {translate("tour.claims.text")}
          </p>
        </div>
      ),
      style: stepStyle
    },
    {
      selector: '.tour.rate',
      content: () => (
        <div>
          <Typography variant='subtitle1'>{translate("tour.rate")}</Typography>
          <p style={{ margin: '5px 0 0' }}>
            {translate("tour.rate.text")}
          </p>
        </div>
      ),
      style: stepStyle
    },
    {
      selector: '.tour.settings',
      content: () => (
        <div>
          <Typography variant='subtitle1'>{translate("tour.settings")}</Typography>
          <p style={{ margin: '5px 0 0' }}>
            {translate("tour.settings.text")}
          </p>
        </div>
      ),
      style: stepStyle
    }
  ]

  const step = !commodate ? steps?.filter(step => step.selector !== '.tour.commodate') : steps

  return (
    <Tour
      steps={step || steps}
      isOpen={isTourOpen}
      rounded={5}
      maskclassname="mask"
      accentcolor="#269faf"
      onRequestClose={() => setIsTourOpen(false)} 
      nextButton={<span style={{ color: '#7825bd', fontSize: '0.95rem', padding: '6px 16px' }}>{translate('tour.next')}</span>}
      prevButton={<span style={{ fontSize: '0.95rem', padding: '6px 16px' }}>{translate('tour.previous')}</span>}
      showNavigation={false}
      showNumber={false}
      className='tour tour-step'
      lastStepNextButton={<span style={{ color: '#7825bd', fontSize: '0.95rem', padding: '6px 16px' }}>{translate('tour.done')}</span>}
    />
  );
}

export default TourMenu;