import React, { useContext } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { I18nContext } from 'translations';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { formatCost } from 'utils';

function CardBalance({ list, type }) {
  const { translate } = useContext(I18nContext);

  return (
    <Grid key={type} item md={type === 'total_balance' ? 6 : 3} xs={4}>
      <div
        className={type === 'total_balance' ? 'animation-background' : 'background-gray-balance'} 
        style={{ margin: '15px 0', padding: '18px' }}
      >
        <Typography variant="body2" style={{ color: 'white' }}>
          { type === 'total_balance' ? `${translate('app.payments.availableBalance')} ${translate('balances.balance.transactions.general')}` : `${translate('app.payments.availableBalance')} ${type}`}:
        </Typography>
        <Typography variant="h5">
          { list[type] && typeof list[type] === 'object' && 'subunits' in list[type] && 'iso' in list[type] ? formatCost(list[type]) : '$ 0.0' }
        </Typography>
        <LocalAtmIcon className="circle" />
      </div>
    </Grid>
  )
}

export default CardBalance;