import React from 'react';

const PhoneIcon = ({ size = 80, color = '#842AD2' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 60 80"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.75 0C3.9175 0 0 3.91751 0 8.75V71.25C0 76.0825 3.9175 80 8.75 80H51.25C56.0825 80 60 76.0825 60 71.25V8.75C60 3.91751 56.0825 0 51.25 0H8.75ZM7.5 8.75C7.5 8.05965 8.05965 7.5 8.75 7.5H51.25C51.9405 7.5 52.5 8.05965 52.5 8.75V71.25C52.5 71.9405 51.9405 72.5 51.25 72.5H8.75C8.05965 72.5 7.5 71.9405 7.5 71.25V8.75ZM30 65C32.7614 65 35 62.7615 35 60C35 57.2385 32.7614 55 30 55C27.2386 55 25 57.2385 25 60C25 62.7615 27.2386 65 30 65Z"
      fill={color}
    />
  </svg>
);

export default PhoneIcon;
