import { Container, Typography } from '@material-ui/core';
import React from 'react';

const Subtitle = ({ text }) => {
  return (
    <Container
      style={{
        backgroundColor: '#F7F7F7',
        margin: '1rem 0',
      }}
    >
      <Typography variant="subtitle1">{text}</Typography>
    </Container>
  );
};

export default Subtitle;
