const { makeStyles } = require('@material-ui/core');

export const useCardStyles = makeStyles((theme) => ({
  option: {
    height: '100%',
    padding: '2rem',
    borderRadius: '1rem',
    boxShadow:
      '0px 1px 10px 0px #0000001F,0px 4px 5px 0px #00000024, 0px 2px 4px -1px #00000033',
    border: `2px solid transparent`,
  },
  digitalPreview: {
    maxWidth: '20rem',
  },
  selectedDesign: {
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: `0px 1px 10px 0px ${theme.palette.primary.main},0px 4px 5px 0px #00000024, 0px 2px 4px -1px #00000033`,
  },
}));

export const useDesignStyles = makeStyles((theme) => ({
  blank: {
    color: theme.palette.primary.main,
    background: theme.palette.blank.main,
  },
  purple: {
    color: theme.palette.blank.main,
    background: '#842AD2',
    '& p': {
      color: theme.palette.blank.main,
    },
  },
  gradient: {
    color: theme.palette.blank.main,
    background: 'linear-gradient(180deg, #EE44EB 0%, #842AD2 100%)',
    '& p': {
      color: theme.palette.blank.main,
    },
  },
}));
