import React, { useContext, useEffect, useState } from 'react';
import { qrsFilterTypeStyles } from './styles';
import { Button, ButtonGroup } from '@material-ui/core';
import { I18nContext } from 'translations';

const QrsFilterType = ({ onChange, value }) => {
  const { translate } = useContext(I18nContext);
  const [isActive, setIsActive] = useState(value);

  const classes = qrsFilterTypeStyles();

  useEffect(() => {
    onChange(isActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <ButtonGroup
      disableElevation
      color="primary"
      variant="text"
      className={classes.buttonGroup}
      size="large"
    >
      <Button
        onClick={() => setIsActive('all')}
        className={isActive === 'all' ? classes.selected : classes.notSelected}
      >
        {translate('app.payments.qrCode.filters.all')}
      </Button>
      <Button
        onClick={() => setIsActive('year')}
        className={isActive === 'year' ? classes.selected : classes.notSelected}
      >
        {translate('app.payments.qrCode.filters.year')}
      </Button>
      <Button
        onClick={() => setIsActive('month')}
        className={
          isActive === 'month' ? classes.selected : classes.notSelected
        }
      >
        {translate('app.payments.qrCode.filters.month')}
      </Button>
    </ButtonGroup>
  );
};

export default QrsFilterType;
