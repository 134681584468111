import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Link,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer
} from '@material-ui/core';
import { format } from 'date-fns';
import { I18nContext } from 'translations';
import { formatCost, booleanToString, statusClass } from 'utils';
import { PACKAGES_STATUSES, SIZE_CD, ROLE_COMPANY_NAME, PACKAGES_STATUSES_OPTIMIZING } from 'utils/constants';
import ModalEvidences from 'components/Modals/ModalEvidences';
import EmptyData from '../../EmptyData';
import styles from '../../style';

const PackagesTable = ({
  classes,
  data,
  recordsPerPage,
  getData,
  company,
  user_id
}) => {
  const [currentPage, setCurrentPage] = useState(data.page)
  const { translate } = useContext(I18nContext)
  const [open, setOpen] = useState(false);
  const [evidences, setEvidencies] = useState(null);

  useEffect(() => {
    setCurrentPage(data.page)
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage)
    getData(newPage)
  };

  const handleEvidences = (evidences) => {
    setOpen(true)
    setEvidencies(evidences)
  }

  if (!data || (data && data.data.length <= 0)) {
    return <EmptyData message={translate('thereIsNoData')} />
  }

  return (
    <>
      <TableContainer>
        <Table className={classes.table} size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{translate('app.bookings.table.state')}</TableCell>
              <TableCell>{translate('app.bookings.table.date')}</TableCell>
              <TableCell>
                {translate('app.bookings.table.deliveryAddress')}
              </TableCell>
              {company && (
                <TableCell>
                  {translate('app.bookings.table.orderNumber')}
                </TableCell>
              )}
              <TableCell>
                {translate('app.bookings.table.declaredValue')}
              </TableCell>
              {company && (
                <TableCell>
                  {translate('app.bookings.table.counterDelivery')}
                </TableCell>
              )}
              {company && (
                <TableCell>
                  {translate('app.bookings.table.counterDeliveryFee')}
                </TableCell>
              )}
              <TableCell>{translate('app.bookings.table.size')}</TableCell>
              {!user_id && company && (
                <TableCell>{translate('app.bookings.table.venue')}</TableCell>
              )}
              {company && (
                <TableCell>
                  {translate('app.bookings.table.costCenter')}
                </TableCell>
              )}
              <TableCell>{translate('evidence')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data.data || []).map((pack) => (
              <TableRow hover key={pack._id}>
                <TableCell>
                  <span
                    className={statusClass(pack.status_cd)}
                    style={{ padding: '3px 7px' }}
                  >
                    {translate(PACKAGES_STATUSES_OPTIMIZING[(pack.status_optimization)]) || translate(PACKAGES_STATUSES[(pack.status_cd)])}
                  </span>
                </TableCell>
                <TableCell>
                  {format(new Date(pack.created_at), 'dd/MM/yyyy, hh:mm a')}
                </TableCell>
                <TableCell>
                  <Link
                    href={`/bookings/${pack.booking_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="primary"
                    underline="hover"
                  >
                    {pack.pick_up_address}
                  </Link>
                </TableCell>
                {company && <TableCell>{pack.reference}</TableCell>}
                <TableCell>
                  {pack?.declared_value && formatCost(pack.declared_value)}
                </TableCell>
                {company && (
                  <TableCell>
                    {pack?.collected_value
                      ? formatCost(pack.collected_value)
                      : translate(booleanToString(pack.counter_delivery))}
                  </TableCell>
                )}
                {company && (
                  <TableCell>
                    {pack?.collection_fee
                      ? formatCost(pack.collection_fee)
                      : translate(booleanToString(pack.collection_fee))}
                  </TableCell>
                )}
                <TableCell>{translate(SIZE_CD[pack.size_cd])}</TableCell>
                {!user_id && company && (
                  <TableCell>
                    {ROLE_COMPANY_NAME[pack.company_area_name] !== undefined
                      ? translate(ROLE_COMPANY_NAME[pack.company_area_name])
                      : pack.company_area_name}
                  </TableCell>
                )}
                {company && (
                  <TableCell className={classes.status}>
                    {pack.cost_center ? pack.cost_center.name : ' - '}
                  </TableCell>
                )}
                <TableCell>
                  <Button type="button" color="primary" onClick={() => handleEvidences(pack.evidences)}>
                    {(pack?.evidences?.picked || pack?.evidences?.delivered) &&
                      translate('evidence')
                    }
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.total ? data.total : data.pages * recordsPerPage}
        page={currentPage - 1}
        rowsPerPageOptions={[]}
        rowsPerPage={recordsPerPage}
        onChangePage={(event, newPage) => handleChangePage(event, newPage + 1)}
      />
      {open && 
        <ModalEvidences
          handleModal={() => setOpen(!open)}
          open={open}
          evidences={evidences}
        />
      }
    </>
  )
}

function areEqual(prevProps, nextProps) {
  return prevProps.data === nextProps.data;
}

export default withStyles(styles)(React.memo(PackagesTable, areEqual));
