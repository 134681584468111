import {
  LIST_QRS,
  LIST_QRS_LOADING,
  CREATE_QR_LOADING,
  GET_COMPANY_INFO_LOADER,
  GET_COMPANY_INFO,
  GET_COMPANY_CHILDREN,
  PATCH_QR_STATUS_LOADING,
  GET_QR_IMAGE_LOADING,
  DELETE_QR_LOADING,
  GET_QR,
  GET_QR_LOADING,
  GET_QR_TAGS,
} from './constants';

const qrsReducer = (state, action) => {
  switch (action.type) {
    case LIST_QRS:
      return {
        ...state,
        list: action.list,
      };
    case LIST_QRS_LOADING:
      return {
        ...state,
        loadingQrs: action.loadingQrs,
      };
    case CREATE_QR_LOADING:
      return {
        ...state,
        createLoading: action.createLoading,
      };
    case GET_COMPANY_INFO_LOADER:
      return {
        ...state,
        companyInfoLoading: action.companyInfoLoading,
      };
    case GET_COMPANY_INFO:
      return {
        ...state,
        companyInfo: action.companyInfo,
      };
    case GET_COMPANY_CHILDREN:
      return {
        ...state,
        companyChildren: action.companyChildren,
      };
    case PATCH_QR_STATUS_LOADING:
      return {
        ...state,
        qrStatusLoading: action.qrStatusLoading,
      };
    case GET_QR_IMAGE_LOADING:
      return {
        ...state,
        qrImageLoading: action.qrImageLoading,
      };
    case DELETE_QR_LOADING:
      return {
        ...state,
        qrDeleteLoading: action.qrDeleteLoading,
      };
    case GET_QR:
      return {
        ...state,
        qr: action.qr,
      };
    case GET_QR_LOADING:
      return {
        ...state,
        qrLoading: action.qrLoading,
      };
    case GET_QR_TAGS:
      return {
        ...state,
        qrTags: action.qrTags,
      };
    default:
      return state;
  }
};

export default qrsReducer;
