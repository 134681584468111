import { makeStyles } from '@material-ui/core';

export const qrsFilterTypeStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  buttonGroup: (props) => ({
    display: 'flex',
    gap: '1rem',
    justifyContent: 'space-between',
    '& .MuiButtonGroup-groupedTextPrimary:not(:last-child)': {
      borderColor: 'none',
      border: 'none',
    },
  }),
  selected: {
    color: theme.palette.primary.main,
  },
  notSelected: {
    color: theme.palette.text.primary,
  },
}));
