import React, { useState, useContext, useCallback, useEffect } from 'react';
import { I18nContext } from 'translations';
import Title from 'components/Titles';
import ListRoundedIcon from '@material-ui/icons/ListRounded';
import { listClaims, reOpenPqr } from 'context/claims/actions';
import { useStateValue } from 'context/store';
import { PER_PAGE, INITIAL_PAGE } from 'utils/constants';
import Loading from 'components/Loading';
import ClaimsTable from 'components/Tables/Claims';
import ClaimsTableFilters from 'components/Tables/Claims/Filters/ClaimsTableFilters';
import ModalClaimsTracking from 'components/Modals/ModalClaims/ModalClaimsTracking';
import { piboxAdminRoles } from 'utils';

export default function Claims() {
  const { translate } = useContext(I18nContext);
  const [per_page] = useState(PER_PAGE);
  const [filters, setFilters] = useState(null);
  const [open, setOpen] = useState(false);
  const [claimId, setClaimId] = useState(null);
  const [
    {
      commons: { loading },
      claims: { list },
      checkin: { checkin }
    },
    dispatch,
  ] = useStateValue();

  const getClaims = useCallback(
    async (page) => {
      await listClaims(dispatch, { page, per_page, ...filters });
    },
    [dispatch, filters, per_page]
  );

  useEffect(() => {
    getClaims(INITIAL_PAGE)
  }, [getClaims])


  const onFilters = params => setFilters(params);

  const openClaim = (id) => {
    setOpen(!open)
    setClaimId(id)
  }

  const reOpenClaim = async (id) => {
    const { status } = await reOpenPqr(dispatch, id);
  
    if (status) {
      listClaims(dispatch, {});
    }
  };  

  return (
    <div className="padding-page-full-height">
      {loading && <Loading />}

      <Title
        title={translate('claims.title')}
        icon={<ListRoundedIcon className="align-center" style={{ marginRight: 5 }} />}
      />
      <div className="marginFiltersTable">
        <ClaimsTableFilters
          onFilters={onFilters}
          getClaims={getClaims}
        />
      </div>
      
      {list &&
        <ClaimsTable
          openClaim={openClaim}
          data={list}
          recordsPerPage={per_page}
          getClaims={getClaims}
          reOpenClaim={reOpenClaim}
        />
      }

      {open &&
        <ModalClaimsTracking
          openClaim={openClaim}
          open={open}
          id={claimId}
          role={piboxAdminRoles(checkin?.profile.roles)}
        />
      }
     
    </div>
  );
}
