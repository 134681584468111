import { Chip, Grid } from '@material-ui/core';
import React from 'react';
import QrsStats from '../Qrs/QrsStats';

const QrDetailStats = ({ qr_summary, isQrDetail = false }) => {
  return (
    <Grid item xs={12}>
      <Grid
        container
        spacing={2}
        style={{
          overflowY: 'scroll',
          maxHeight: '180px',
        }}
      >
        {Object.entries(qr_summary).map((tag, index) => (
          <Grid item xs={12} key={index}>
            <Chip label={tag[0]} style={{ marginBottom: '1rem' }} />
            <QrsStats stats={tag[1]} isQrDetail={isQrDetail} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default QrDetailStats;
