import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

export default function QrTagsSelector({
  label,
  error,
  helperText,
  onChange,
  value = [], // asegúrate de que value es un array
  size = 'small',
}) {
  const [options, setOptions] = useState(value || []);
  const [autoCompleteValue, setAutoCompleteValue] = useState(value || []); // usa el primer valor de value o una cadena vacía
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (inputValue && !options.includes(inputValue)) {
      setOptions([...options, inputValue]);
    }
  }, [inputValue, options]);

  useEffect(() => {
    onChange(autoCompleteValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoCompleteValue]);

  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      getOptionLabel={(option) => option}
      options={options}
      value={autoCompleteValue}
      onChange={(event, newValue) => {
        setAutoCompleteValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
          size={size}
          variant="outlined"
        />
      )}
    />
  );
}
