export const SERVICE_TYPE_ID = '5c71b03a58b9ba10fa6393cf';

export const CREATE_BOOKING = 'CREATE_BOOKING';
export const QUOTE = 'QUOTE';
export const BULK_LOAD = 'BULK_LOAD';
export const BULK_LOAD_FAILED = 'BULK_LOAD_FAILED';
export const OPTIMIZE_STOPS = 'OPTIMIZE_STOPS';
export const LIST_BOOKINGS = 'LIST_BOOKINGS';
export const LIST_BOOKINGS_LOADING = 'LIST_BOOKINGS_LOADING';
export const LIST_PACKAGES = 'LIST_PACKAGES';
export const LIST_PACKAGES_LOADING = 'LIST_PACKAGES_LOADING';
export const DOWNLOAD_EXCEL_BOOKING = 'DOWNLOAD_EXCEL_BOOKING';
export const LIST_BY_GROUPS = 'LIST_BY_GROUPS';
export const RELATED_CITIES = 'RELATED_CITIES';
export const LIST_REPORTS = 'LIST_REPORTS';
export const PROMO_CODE_REDEEM = 'PROMO_CODE_REDEEM';
export const LOADING_PROMO_CODE_REDEEM = 'LOADING_PROMO_CODE_REDEEM';
export const BULK_SCHEDULED_FAILED = 'BULK_SCHEDULED_FAILED';
export const BULK_SCHEDULED_SUCCESS = 'BULK_SCHEDULED_SUCCESS';
export const GET_BALANCE = 'GET_BALANCE';