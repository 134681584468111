import React, { useState, createContext, useEffect, useContext } from 'react';
import Title from 'components/Titles';
import { Grid } from '@material-ui/core';
import CreateQr from './CreateQr';
import QrsDateFilters from './QrsDateFilters';
import QrsStats from './QrsStats';
import QrsFilters from './QrsTables/QrsFilters';
import PqrsTables from './QrsTables';
import QrIcon from 'components/Icons/Qr';
import { useStateValue } from 'context/store';
import { getCompanyInfo, listQrs } from 'context/qr/actions';
import Loading from 'components/Loading';
import { cleanObject } from 'utils';
import { I18nContext } from 'translations';

export const QrsContext = createContext();

const Qrs = () => {
  const { translate } = useContext(I18nContext);

  const filtersRestObject = {
    initDate: null,
    endDate: null,
    filter_type: 'all',
  };
  const [filters, setFilters] = useState({
    ...filtersRestObject,
    name: '',
    company_id: '',
    status: '',
  });

  const [activeTab, setActiveTab] = useState(0);

  const [
    {
      checkin: { checkin },
      qrs: {
        list,
        loadingQrs,
        qrStatusLoading,
        qrImageLoading,
        qrDeleteLoading,
        companyChildren,
      },
    },
    dispatch,
  ] = useStateValue();

  const getQrs = () => {
    const params = {
      ...cleanObject(filters),
      company_id: filters.company_id || checkin?.company._id,
    };
    listQrs({
      dispatch,
      params,
    });
  };

  useEffect(() => {
    if (checkin?.company) {
      getQrs();
      getCompanyInfo(dispatch, checkin.company._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkin.company, filters]);

  if (loadingQrs || !list) return <Loading />;

  return (
    <QrsContext.Provider
      value={{
        filters,
        setFilters,
        list,
        loadingQrs,
        qrStatusLoading,
        qrImageLoading,
        qrDeleteLoading,
        dispatch,
        checkin,
        activeTab,
        setActiveTab,
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} container style={{ padding: '1rem' }}>
          <Grid item xs={12} sm={9} md={10}>
            <Title
              title={translate('app.payments.qrCode.title')}
              icon={<QrIcon size={'3rem'} />}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <Grid container justify="flex-end">
              <CreateQr company={checkin.company} filters={filters} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container style={{ padding: '1rem' }}>
          <Grid item xs={12} container justify="flex-end">
            <QrsDateFilters
              filters={filters}
              setFilters={setFilters}
              filtersRestObject={filtersRestObject}
            />
          </Grid>
          <Grid item xs={12}>
            <QrsStats stats={list.summary} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <QrsFilters
              filters={filters}
              companyChildren={companyChildren}
              onFiltersChange={(newFilters) =>
                setFilters({ ...filters, ...newFilters })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <PqrsTables filters={filters} />
          </Grid>
        </Grid>
      </Grid>
    </QrsContext.Provider>
  );
};

export default Qrs;
