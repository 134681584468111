import React from 'react';

const QrIcon = ({ size = 80, color = '#842AD2' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 80 80"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0V29.0909H7.27273V36.3636H14.5455V29.0909H29.0909V0H0ZM29.0909 29.0909V36.3636H36.3636V43.6364H21.8182V50.9091H0V80H29.0909V50.9091H50.9091V43.6364H43.6364V36.3636H58.1818V29.0909H65.4545V36.3636H72.7273V29.0909H80V0H50.9091V29.0909H29.0909ZM72.7273 36.3636V43.6364H80V36.3636H72.7273ZM72.7273 43.6364H65.4545V50.9091H72.7273V43.6364ZM72.7273 50.9091V58.1818H80V50.9091H72.7273ZM72.7273 58.1818H65.4545V50.9091H58.1818V58.1818H40V80H47.2727V65.4545H61.8182V72.7272H69.0909V65.4545H72.7273V58.1818ZM61.8182 72.7272H54.5455V80H61.8182V72.7272ZM65.4545 43.6364V36.3636H58.1818V43.6364H65.4545ZM21.8182 43.6364V36.3636H14.5455V43.6364H21.8182ZM7.27273 36.3636H0V43.6364H7.27273V36.3636ZM36.3636 0V14.5455H32.7273V21.8182H36.3636V25.4545H43.6364V14.5455H47.2727V7.27273H43.6364V0H36.3636ZM7.27273 7.27271H21.8182V21.8182H7.27273V7.27271ZM58.1818 7.27271H72.7273V21.8182H58.1818V7.27271ZM10.9091 10.9091V18.1818H18.1818V10.9091H10.9091ZM61.8182 10.9091V18.1818H69.0909V10.9091H61.8182ZM7.27273 58.1818H21.8182V72.7273H7.27273V58.1818ZM10.9091 61.8182V69.0909H18.1818V61.8182H10.9091ZM72.7273 72.7272V80H80V72.7272H72.7273Z"
      fill={color}
    />
  </svg>
);

export default QrIcon;
