import { createStyles } from '@material-ui/core/styles';

const drawerWidth = 210;

const styles = createStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'inherit',
    zIndex: '999',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed'
    },
    '& a': {
      padding: '6px 12px',
      '&:hover': {
        backgroundColor: 'transparent',
        '& span, & svg': {
          color: theme.palette.primary.main
        }
      }
    }
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
  },
  contentLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1, 1),
    ...theme.mixins.toolbar,
    '& img' : {
      maxWidth: '100%'
    }
  },
  iconPrimary: {
    minWidth: 'auto',
    marginRight: 6,
    '& svg': {
      fontSize: 16
    }
  },
  submenu: {
    position: 'absolute',
    left: '100%',
    zIndex: '99999',
    top: 0,
    backgroundColor: '#fff',
    borderRadius: '2px',
    display: 'none',
    minWidth: '170px',
    boxShadow: '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
    '& span, & a': {
      color: '#545454 !important'
    },
    '& a': {
      padding: '5px 16px'
    }
  },
  listWrap: {
    padding: 0,
    '&:hover $submenu': {
      display: 'block',
      transition: 'all .5s ease-in',
      '& a:hover': {
        backgroundColor: 'transparent',
        '& span': {
          color: `${theme.palette.primary.main} !important`
        }
      }
    },
    '& svg': {
      color: '#545454',
    },
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  active: {
    '& span, & svg': {
      color: theme.palette.primary.main
    }
  },
  company: {
    position: 'absolute',
    bottom: 12,
    textAlign: 'center',
    left: 0,
    right: 0,
    whiteSpace: 'break-spaces',
    '& li span': {
      fontFamily: 'amaranthbold',
      lineHeight: '17px',
    }
  },
}));

export default styles;