import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const Title = ({ title = 'Pibox', icon, isImage, subtitle }) => (
  <Grid container spacing={1} alignContent="center">
    <Grid item xs={12} md={12}>
      <Typography
        variant="h5"
        color="primary"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {isImage ? (
          <img src={icon} alt="icon" style={{ width: '2.5rem' }} />
        ) : (
          icon && icon
        )}
        <span className="align-center" style={{ marginLeft: '0.5rem' }}>
          {title}
        </span>
      </Typography>
      <Typography variant="subtitle2">{subtitle}</Typography>
    </Grid>
  </Grid>
);

export default Title;
