import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { getQrImage } from 'context/qr/actions';
import { useStateValue } from 'context/store';
import React, { useContext } from 'react';
import { I18nContext } from 'translations';

const QrSpecificDetails = ({ qr, qr_summary }) => {
  const { translate } = useContext(I18nContext);
  const [
    {
      qrs: { qrImageLoading },
    },
    dispatch,
  ] = useStateValue();
  const handleDownload = () => {
    getQrImage({
      dispatch,
      qrId: qr.id,
    });
  };
  return (
    <Grid
      item
      xs={12}
      sm={5}
      md={3}
      lg={2}
      style={{
        borderRadius: '1rem',
        boxShadow: '0px 1px 2px 0px #0000000F, 0px 1px 3px 0px #0000001A',
        padding: '1rem',
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{
            overflow: 'hidden',
            borderRadius: '1rem',
            border: '1px solid #E0E0E0',
          }}
        >
          <img src={qr.image} alt="qr_code_image" style={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">{qr.name}</Typography>
          <Typography variant="body1">
            <strong>
              {translate('app.payments.qrCode.specificDetails.created')}:{' '}
            </strong>{' '}
            {qr.created_at}
          </Typography>
          <Typography variant="body1">
            <strong>
              {translate('app.payments.qrCode.specificDetails.wallet')}
            </strong>{' '}
            {qr.wallet_id}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            startIcon={qrImageLoading && <CircularProgress size="1rem" />}
            variant="outlined"
            fullWidth
            onClick={handleDownload}
          >
            {translate('app.payments.qrCode.specificDetails.download')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} style={{ border: '1px solid #ccc' }}>
            <Grid
              item
              xs={12}
              container
              justify="center"
              style={{
                borderBottom: '1px solid #ccc',
              }}
            >
              <Typography variant="subtitle1">
                {translate(
                  'app.payments.qrCode.specificDetails.timesDownloaded'
                )}
              </Typography>
            </Grid>
            <Box
              flexGrow={1}
              style={{ overflowY: 'scroll', maxHeight: '180px' }}
            >
              {Object.entries(qr_summary).map((tag, index) => {
                return (
                  <Grid key={index} item xs={12} style={{ padding: '0.5rem' }}>
                    <Chip label={tag[0]} />
                    <Typography variant="subtitle1">
                      {tag[1][0].value}
                    </Typography>
                  </Grid>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QrSpecificDetails;
