export const LIST_QRS = 'LIST_QRS';
export const LIST_QRS_LOADING = 'LIST_QRS_LOADING';
export const CREATE_QR_LOADING = 'CREATE_QR_LOADING';
export const GET_COMPANY_INFO = 'GET_COMPANY_INFO';
export const GET_COMPANY_INFO_LOADER = 'GET_COMPANY_INFO_LOADER';
export const GET_COMPANY_CHILDREN = 'GET_COMPANY_CHILDREN';
export const PATCH_QR_STATUS_LOADING = 'PATCH_QR_STATUS_LOADING';
export const GET_QR_IMAGE_LOADING = 'GET_QR_IMAGE_LOADING';
export const DELETE_QR_LOADING = 'DELETE_QR_LOADING';
export const GET_QR = 'GET_QR';
export const GET_QR_LOADING = 'GET_QR_LOADING';
export const GET_QR_TAGS = 'GET_QR_TAGS';
