import React, { useContext, useEffect } from 'react';
import QrModal from './QrModal';
import QrForm from './QrForm';
import AddIcon from '@material-ui/icons/Add';
import { createQr, getCompanyInfo } from 'context/qr/actions';
import { modalMessage } from 'utils';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import Loading from 'components/Loading';

const CreateQr = ({ company, filters }) => {
  const { translate } = useContext(I18nContext);

  const [
    {
      qrs: { createLoading, companyInfoLoading, companyInfo, companyChildren },
    },
    dispatch,
  ] = useStateValue();

  useEffect(() => {
    if (company) {
      getCompanyInfo(dispatch, company._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const messageOk = () => {
    modalMessage(
      '',
      translate(
        'app.payments.qrCode.create.confirmationPhysical.confirmMessage'
      ),
      'success',
      translate('accept'),
      true
    );
  };
  const createQrHandler = async ({ dispatch, payload, callback }) => {
    try {
      const response = await createQr({ dispatch, payload, filters });
      if (response?.status) {
        messageOk();
        callback();
      }
    } catch (error) {
      console.error('Error creating qr', error);
    }
  };

  const handleFormSubmit = (values, handleClose) => {
    const payload = {
      qr_info: {
        name: values.qr_name,
        city: values.city._id,
        type: values.qr_type,
        design: values.qr_design,
        tags: values.tags,
        talkers: values.quantity,
        address: values.delivery_address,
      },
      company_id: company._id,
    };
    createQrHandler({ dispatch, payload, callback: handleClose });
  };

  return (
    <QrModal
      title={translate('app.payments.qrCode.create')}
      buttonTitle={translate('app.payments.qrCode.create')}
      buttonIcon={<AddIcon fontSize="small" color="primary" />}
    >
      {companyInfoLoading ? (
        <Loading />
      ) : (
        <QrForm
          handleFormSubmit={handleFormSubmit}
          company={companyInfo}
          companyChildren={companyChildren}
          loading={createLoading}
        />
      )}
    </QrModal>
  );
};

export default CreateQr;
