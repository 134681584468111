import React from 'react';
import {
  Typography,
  IconButton,
  Tooltip as TooltipText,
  Grid,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

export default function QrLineGraph({
  title,
  textLeft,
  pointRadius,
  datasets,
  dates,
  tooltipMssg,
}) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        display: true,
        ticks: {
          stepSize: 1,
        },
        title: {
          display: true,
          text: textLeft,
        },
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  function getRandomColor() {
    const r = Math.floor(Math.random() * 256); // Random between 0-255
    const g = Math.floor(Math.random() * 256); // Random between 0-255
    const b = Math.floor(Math.random() * 256); // Random between 0-255
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  }

  const data = {
    labels: dates,
    datasets: Object.entries(datasets).map((dataset) => ({
      label: dataset[0],
      data: dataset[1],
      backgroundColor: getRandomColor(),
      // borderColor: '#7825bd',
      pointRadius: pointRadius,
    })),
  };

  return (
    <>
      <Typography
        variant="subtitle1"
        style={{ borderBottom: '1px solid #ccc' }}
        color="textPrimary"
      >
        <b>
          {title}
          {tooltipMssg && (
            <TooltipText title={tooltipMssg} placement="top-start">
              <IconButton size="small">
                <InfoOutlinedIcon />
              </IconButton>
            </TooltipText>
          )}
        </b>
      </Typography>
      <br />
      <Grid
        style={{
          height: '100%',
        }}
      >
        <Line options={options} data={data} />
      </Grid>
    </>
  );
}
