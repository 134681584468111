import { post, get, patch} from 'api/requests';
import { urlCompanyPromoCode, urlQrs } from 'api';
import { message } from '../commons/actions';
import handleErrorMessage from 'utils/handleErrorMessage';
import { COUNTRY_CODE } from 'utils/constants';
import { formatPrice } from 'utils';
import {
  CREATE_QR_LOADING,
  DELETE_QR_LOADING,
  GET_COMPANY_CHILDREN,
  GET_COMPANY_INFO,
  GET_COMPANY_INFO_LOADER,
  GET_QR,
  GET_QR_IMAGE_LOADING,
  GET_QR_LOADING,
  GET_QR_TAGS,
  LIST_QRS,
  LIST_QRS_LOADING,
  PATCH_QR_STATUS_LOADING,
} from './constants';

export const createQr = async ({ dispatch, payload, filters }) => {
  let status = false;
  dispatch({
    type: CREATE_QR_LOADING,
    createLoading: true,
  });
  await post(`${urlQrs}`, {
    ...payload,
  })
    .then(async (json) => {
      await listQrs({
        dispatch,
        params: { ...filters, company_id: payload.company_id },
      });
      return json.data;
    })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    })
    .finally(() => {
      dispatch({
        type: CREATE_QR_LOADING,
        createLoading: false,
      });
    });
  return { status };
};

export const listQrs = async ({ dispatch, params }) => {
  dispatch({
    type: LIST_QRS_LOADING,
    loadingQrs: true,
  });
  await get(`${urlQrs}`, {
    ...params,
  })
    .then(({ data }) => {
      dispatch({
        type: LIST_QRS,
        list: data,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
    })
    .finally(() => {
      dispatch({
        type: LIST_QRS_LOADING,
        loadingQrs: false,
      });
    });
};

export const getCompanyInfo = async (dispatch, companyId) => {
  dispatch({
    type: GET_COMPANY_INFO_LOADER,
    companyInfoLoading: true,
  });
  await get(`${urlQrs}/company_info`, {
    company_id: companyId,
  })
    .then(async ({ data }) => {
      dispatch({
        type: GET_COMPANY_INFO,
        companyInfo: data,
      });
      await getCompanyChildren(dispatch, companyId);
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
    })
    .finally(() => {
      dispatch({
        type: GET_COMPANY_INFO_LOADER,
        companyInfoLoading: false,
      });
    });
};

export const getCompanyChildren = async (dispatch, companyId) => {
  await get(`${urlQrs}/company_children`, {
    company_id: companyId,
  })
    .then(({ data }) => {
      dispatch({
        type: GET_COMPANY_CHILDREN,
        companyChildren: data,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
    });
};

export const deleteQr = async ({ dispatch, qrId, params }) => {
  dispatch({
    type: DELETE_QR_LOADING,
    qrDeleteLoading: true,
  });
  let status = false;
  await patch(`${urlQrs}/${qrId}/delete_image`)
    .then(() => {
      status = true;
      listQrs({ dispatch, params });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    })
    .finally(() => {
      dispatch({
        type: DELETE_QR_LOADING,
        qrDeleteLoading: false,
      });
    });
  return { status };
};

export const toggleQrStatus = async ({ dispatch, qrId, params }) => {
  dispatch({
    type: PATCH_QR_STATUS_LOADING,
    qrStatusLoading: true,
  });
  let status = false;
  await patch(`${urlQrs}/${qrId}/change_status`)
    .then(() => {
      status = true;
      listQrs({ dispatch, params });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    })
    .finally(() => {
      dispatch({
        type: PATCH_QR_STATUS_LOADING,
        qrStatusLoading: false,
      });
    });
  return { status };
};

export const getQrImage = async ({ dispatch, qrId }) => {
  dispatch({
    type: GET_QR_IMAGE_LOADING,
    qrImageLoading: true,
  });
  await get(`${urlQrs}/${qrId}/download_image`)
    .then(({ data }) => {
      const link = document.createElement('a');
      link.href = data.url;
      link.target = '_blank'; // This will open the link in a new tab
      link.download = 'qr.jpg'; // or any other filename you want
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
    })
    .finally(() => {
      dispatch({
        type: GET_QR_IMAGE_LOADING,
        qrImageLoading: false,
      });
    });
};

export const getQr = async ({ dispatch, qrId, payload }) => {
  dispatch({
    type: GET_QR_LOADING,
    qrLoading: true,
  });
  await get(`${urlQrs}/${qrId}`, payload)
    .then(async ({ data }) => {
      dispatch({
        type: GET_QR,
        qr: data,
      });
      await getQrTags({ dispatch, qrId });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
    })
    .finally(() => {
      dispatch({
        type: GET_QR_LOADING,
        qrLoading: false,
      });
    });
};

export const getQrTags = async ({ dispatch, qrId }) => {
  await get(`${urlQrs}/${qrId}/list_tags`)
    .then(({ data }) => {
      dispatch({
        type: GET_QR_TAGS,
        qrTags: data,
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
    });
};

export const assingPromoCode = async (dispatch, companyId, values) => {
  let status = false;
  let unasignables = [];
  await patch(
    `${urlCompanyPromoCode}/${companyId}/corporative_promo_codes/allocate`,
    { ...values }
  )
    .then((json) => {
      return json.data;
    })
    .then((data) => {
      status = true;
      if (data?.unasignables) {
        unasignables.push(...data.unasignables);
      }
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status, unasignables };
};

export const updatePromoCode = async (
  dispatch,
  companyId,
  id,
  values,
  lang
) => {
  const data = {
    ...values,
    amount: {
      sub_units: formatPrice({
        sub_units: values.amount,
        iso: COUNTRY_CODE[lang][4],
      }),
      currency: COUNTRY_CODE[lang][4],
    },
    cost_center: values.cost_center_id,
  };

  let status = false;
  await patch(
    `${urlCompanyPromoCode}/${companyId}/corporative_promo_codes/${id}`,
    { ...data }
  )
    .then((json) => {
      return json.data;
    })
    .then((data) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
};

export const cancelPromoCode = async (dispatch, companyId, values) => {
  let status = false;
  let nonCancellable = [];
  await patch(
    `${urlCompanyPromoCode}/${companyId}/corporative_promo_codes/cancel`,
    { ...values }
  )
    .then((json) => {
      return json.data;
    })
    .then((data) => {
      status = true;
      if (data?.non_cancellable_codes) {
        nonCancellable.push(...data.non_cancellable_codes);
      }
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status, nonCancellable };
};

export const exportPromoCodes = async (dispatch, companyId, params) => {
  let status = false;
  await post(
    `${urlCompanyPromoCode}/${companyId}/corporative_promo_codes/export`,
    { ...params }
  )
    .then(({ data }) => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
};
