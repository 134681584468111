import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumbs,
  Grid,
  Link as LinkMui,
  Typography,
} from '@material-ui/core';
import { I18nContext } from 'translations';

const QrBreadCrumb = () => {
  const { translate } = useContext(I18nContext);
  return (
    <Grid item xs={12} md={6}>
      <Breadcrumbs aria-label="breadcrumb" size="large">
        <LinkMui color="inherit" to="/app/payments/qrs" component={Link}>
          {translate('app.payments.qrCode.breadCrumb.qrs')}
        </LinkMui>
        <Typography color="primary">
          {translate('app.payments.qrCode.breadCrumb.qr')}
        </Typography>
      </Breadcrumbs>
    </Grid>
  );
};

export default QrBreadCrumb;
