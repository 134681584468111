import QrsTable from '../QrsTable';

const useQrsTabsList = ({ t }) => {
  const QrsTabsListArray = [
    {
      title: t('app.payments.qrCode.tab.all'),
      content: QrsTable,
      filter: null,
    },
    {
      title: t('app.payments.qrCode.tab.active'),
      content: QrsTable,
      filter: 'active',
    },
    {
      title: t('app.payments.qrCode.tab.paused'),
      content: QrsTable,
      filter: 'pending',
    },
  ];
  return { QrsTabsList: QrsTabsListArray };
};

export default useQrsTabsList;
