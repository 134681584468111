import { Grid, Paper } from '@material-ui/core';
import React from 'react';
import { useCardStyles, useDesignStyles } from './styles';
import QrIcon from 'components/Icons/Qr';

const QrDesigns = [
  {
    src: '/images/qr/template_0.png',
  },
  {
    src: '/images/qr/template_1.png',
  },
  {
    src: '/images/qr/template_2.png',
  },
];

const DesignPreview = ({ design, isDigital }) => {
  const cardStyles = useCardStyles();
  const designStyles = useDesignStyles();
  return (
    <Paper
      className={`${cardStyles.option} ${
        isDigital && designStyles['gradient']
      }`}
      elevation={0}
    >
      <Grid container spacing={3} justify="center">
        {isDigital ? (
          <QrIcon size={'8rem'} color={'#FFFFFF'} />
        ) : (
          <img
            src={QrDesigns[design].src}
            alt="QR Code"
            style={{ width: '100%' }}
          />
        )}
      </Grid>
    </Paper>
  );
};

export default DesignPreview;
