import React, { createContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, useTheme } from '@material-ui/core';

export const QrModalContext = createContext();

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: '1rem',
    },
  },
  title: {
    '& h2': {
      color: theme.palette.primary.main,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  },
}));

const QrModal = ({ title, buttonTitle, buttonIcon, children }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <QrModalContext.Provider value={{ handleClose }}>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        {buttonIcon}
        {buttonTitle}
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="qr-modal-title"
      >
        <DialogTitle id="qr-modal-title" className={classes.title}>
          {title}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </QrModalContext.Provider>
  );
};

export default QrModal;
